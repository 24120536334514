import IndustryCard from "components/industry/industry-card";
import IndustryHero from "components/industry/industry-hero";
import IndustryDataConstant from "constants/industry";
import React from "react";
import IndustryClientHighlight from "components/industry/client-highlight";
import WhyUsSection from "components/landing/why-us";
import JoinUsSection from "components/common/join-us";
import { useEffect } from "react";

function TravelHospitality() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className="bg-cover bg-ind-travel">
      <IndustryHero title={IndustryDataConstant.TravelHospitality.Hero.title} description={IndustryDataConstant.TravelHospitality.Hero.description} />
      <IndustryCard cardData={IndustryDataConstant.TravelHospitality.Cards} />
    </div>
    <IndustryClientHighlight
      ImageSrc={IndustryDataConstant.TravelHospitality.ClientHighlight.imageSrc}
      Description={IndustryDataConstant.TravelHospitality.ClientHighlight.description}
      bulletPoints={IndustryDataConstant.TravelHospitality.ClientHighlight.bulletPoints}
    />
    <WhyUsSection />
    <JoinUsSection />
    </>
  );
}

export default TravelHospitality;
