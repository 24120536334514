import React from "react";
import ServiceHero from "components/service/service-hero";
import ServiceDataConstant from "constants/services";
import ContactUsForm from "pages/contact-us";
import ServiceCards from "components/service/service-cards";
import WhyUsSection from "components/landing/why-us";
import WorkForYouSection from "components/common/work-for-you";
import ClientHighlight from "components/common/client-highlight";
import { useEffect } from "react";

function ArtificialIntelligence() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <ServiceHero
        image={ServiceDataConstant.ArtificialIntelligence.Hero.image}
        title={ServiceDataConstant.ArtificialIntelligence.Hero.title}
        description={ServiceDataConstant.ArtificialIntelligence.Hero.description} 
      />
      <ServiceCards 
        title={ServiceDataConstant.ArtificialIntelligence.ServiceCards.title}
        description={ServiceDataConstant.ArtificialIntelligence.ServiceCards.description}
        bgColor={ServiceDataConstant.ArtificialIntelligence.ServiceCards.bgColor}
        cardsBgColor={ServiceDataConstant.ArtificialIntelligence.ServiceCards.cardsBgColor}
        cardContent={ServiceDataConstant.ArtificialIntelligence.ServiceCards.Content}
      />
      <ClientHighlight
        Title={ServiceDataConstant.ArtificialIntelligence.ClientHighlight.title}
        ImageSrc={ServiceDataConstant.ArtificialIntelligence.ClientHighlight.image}
        Description={ServiceDataConstant.ArtificialIntelligence.ClientHighlight.description}
        bulletPoints={ServiceDataConstant.ArtificialIntelligence.ClientHighlight.bulletPoints}
      />
      <WorkForYouSection 
        bg={ServiceDataConstant.ArtificialIntelligence.WorkForYou.bg}
        description={ServiceDataConstant.ArtificialIntelligence.WorkForYou.description}
        bulletPoints={ServiceDataConstant.ArtificialIntelligence.WorkForYou.bulletPoints}
      />
      <WhyUsSection />
      <ContactUsForm
        backgroundImage={ServiceDataConstant.ArtificialIntelligence.ContactUs.contactUsBGImage}
        quote={ServiceDataConstant.ArtificialIntelligence.ContactUs.quote}
      />
    </>
  );
}

export default ArtificialIntelligence;
