import React from "react";
import HeroSection from "components/common/hero";
import { useEffect } from "react";
import CareersInfo from "components/careers/info";

function Careers() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <HeroSection 
        primaryText={"Careers"}
        secondaryText={"Join us and start your professional journey"}
        lightImg={"bg-about-us"}
        darkImg={"bg-about-us"}
      />
      <CareersInfo />
    </>
  );
}

export default Careers;
