import ServiceHero from "components/service/service-hero";
import ServiceDataConstant from "constants/services";
import React from "react";
import ContactUsForm from "pages/contact-us";
import WhyUsSection from "components/landing/why-us";
import ServiceCards from "components/service/service-cards";
import WorkForYouSection from "components/common/work-for-you";
import ClientHighlight from "components/common/client-highlight";
import { useEffect } from "react";

function Staffing() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <ServiceHero
          image={ServiceDataConstant.Staffing.Hero.image}
          title={ServiceDataConstant.Staffing.Hero.title}
          description={ServiceDataConstant.Staffing.Hero.description} 
      />
      <ServiceCards 
        title={ServiceDataConstant.Staffing.ServiceCards.title}
        description={ServiceDataConstant.Staffing.ServiceCards.description}
        bgColor={ServiceDataConstant.Staffing.ServiceCards.bgColor}
        cardsBgColor={ServiceDataConstant.Staffing.ServiceCards.cardsBgColor}
        cardContent={ServiceDataConstant.Staffing.ServiceCards.Content}
      />
      <ClientHighlight
        Title={ServiceDataConstant.Staffing.ClientHighlight.title}
        ImageSrc={ServiceDataConstant.Staffing.ClientHighlight.image}
        Description={ServiceDataConstant.Staffing.ClientHighlight.description}
        bulletPoints={ServiceDataConstant.Staffing.ClientHighlight.bulletPoints}
      />
      <WorkForYouSection 
        bg={ServiceDataConstant.Staffing.WorkForYou.bg}
        description={ServiceDataConstant.Staffing.WorkForYou.description}
        bulletPoints={ServiceDataConstant.Staffing.WorkForYou.bulletPoints}
      />
      <WhyUsSection />
      <ContactUsForm
        backgroundImage={ServiceDataConstant.Staffing.ContactUs.contactUsBGImage}
        quote={ServiceDataConstant.Staffing.ContactUs.quote}
      />
    </>
  );
}

export default Staffing;
