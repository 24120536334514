import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as ACRLogo } from "assets/logo/logo-white.svg";
import ThemeContext from "contexts/ThemeContext";
import NavbarPaths from "constants/navbar-options";
import close from "../../../assets/icons/close.png";
import menu from "../../../assets/icons/menu.png";
import { FaAngleDown } from "react-icons/fa";

const Navbar = () => {
  const { theme } = React.useContext(ThemeContext);
  const [toggle, setToggle] = useState(false);

  const handleDropDownOptionSelect = () => {
    const elem = document.activeElement;
    if (elem) {
      elem?.blur();
    }
  };

  return (
    <header id='navbar' className='navbar py-4 sticky top-0 z-20 bg-cover bg-navbar'>
      <NavLink to='/' className='flex-1'>
        <ACRLogo
          className={`w-10 h-10 lg:w-20 lg:h-20`}
        />
        <div className='flex flex-col mx-8'>
          <h1 className='font-poppins text-2xl lg:text-5xl text-white'>ACR Technology</h1>
          <h2 className='hidden lg:block font-poppins text-xl mt-1 text-white'>Consulting and Staffing Services</h2>
        </div>
      </NavLink>
      <div className='hidden md:flex flex-none text-3xl text-white'>
        <ul className='menu menu-horizontal px-1'>
          <li tabIndex={0}>
            <span className="hover:text-white">
            <NavLink to={'/'} className='text-lg link-hover'>
              Home
            </NavLink>
            </span>
          </li>
          {Object.keys(NavbarPaths).map((navOption) => {
            return (
              <li key={navOption} tabIndex={0} className='dropdown dropdown-end hover:text-white' >
                <span className="hover:text-white">
                  <summary className='text-lg link-hover contents'>{navOption}&nbsp; <FaAngleDown/></summary>
                </span>
                <ul className='dropdown-content text-lg rounded-lg' data-theme={theme}>
                  {NavbarPaths[navOption].map((route) => {
                    return (
                      <li key={route.path} onClick={handleDropDownOptionSelect}>
                        <NavLink to={route.path} className='text-lg link link-hover'>
                          {route.text}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
          <li tabIndex={0}>
            <span>
            <NavLink to={'/about-us'} className='text-lg link link-hover'>
              About Us
            </NavLink>
            </span>
          </li>
        </ul>
      </div>

      {/* mobile */}
      <div className="md:hidden flex flex-1 justify-end items-center">
          {toggle ? (
            <div
              className={`p-6 bg-slate-200 opacity-[0.98] absolute 
                top-0 left-0 w-screen h-[100vh] z-10 menu ${
                  toggle ? "menu-open" : "menu-close"
                }`}
            >
              <div className="flex justify-end">
                <img
                  src={close}
                  alt="close"
                  className="w-[22px] h-[22px] object-contain cursor-pointer"
                  onClick={() => setToggle(!toggle)}
                />
              </div>
              <ul className='menu px-1'>
          <li tabIndex={0} className="max-w-[30%]" onClick={() => {setToggle(!toggle)}}>
            <span>
            <NavLink to={'/'} className='text-lg link link-hover'>
              Home
            </NavLink>
            </span>
          </li>
          {Object.keys(NavbarPaths).map((navOption) => {
            return (
              <li key={navOption} tabIndex={0} className='dropdown dropdown-right max-w-[50%]' >
                <span>
                  <summary className='text-lg'>{navOption}&nbsp;&nbsp;&nbsp;{'>'}</summary>
                </span>
                <ul className='dropdown-content text-lg rounded-lg' data-theme={theme}>
                  {NavbarPaths[navOption].map((route) => {
                    return (
                      <li key={route.path} onClick={() => {setToggle(!toggle)}}>
                        <NavLink to={route.path} className='text-lg link link-hover'>
                          {route.text}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
          <li className="max-w-[30%]" onClick={() => {setToggle(!toggle)}}>
            <span>
            <NavLink to={'/about-us'} className='text-lg link link-hover'>
              About Us
            </NavLink>
            </span>
          </li>
        </ul>
            </div>
          ) : (
            <img
              src={menu}
              alt="menu"
              className="w-[34px] h-[34px] object-contain cursor-pointer ml-[20px] xs:ml-[40px]"
              onClick={() => setToggle(!toggle)}
            />
          )}
        </div>
    </header>
  );
};

export default Navbar;
