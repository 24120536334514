import React, { useEffect } from "react";
import SolutionDataConstant from "constants/solution";
import SolutionHero from "components/solution/solution-hero";
import ContactUsForm from "pages/contact-us";
import ClientHighlight from "components/common/client-highlight";
import SolutionProcess from "components/solution/solution-process";

function ACRPharmLaunch() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <SolutionHero 
        title={SolutionDataConstant.ACRPharmLaunch.Hero.title}
        image={SolutionDataConstant.ACRPharmLaunch.Hero.image}
        description={SolutionDataConstant.ACRPharmLaunch.Hero.description}
        highlight={SolutionDataConstant.ACRPharmLaunch.Hero.highlight}
      />
      <ClientHighlight
        Title={SolutionDataConstant.ACRPharmLaunch.ClientHighlight.title}
        ImageSrc={SolutionDataConstant.ACRPharmLaunch.ClientHighlight.image}
        Description={SolutionDataConstant.ACRPharmLaunch.ClientHighlight.description}
        bulletPoints={SolutionDataConstant.ACRPharmLaunch.ClientHighlight.bulletPoints}
      />
      <SolutionProcess
        PrimaryImage={SolutionDataConstant.ACRPharmLaunch.Process.primaryImage}
        SecondaryImage={SolutionDataConstant.ACRPharmLaunch.Process.secondaryImage}
        Description={SolutionDataConstant.ACRPharmLaunch.Process.description}
      />
      <ContactUsForm
        backgroundImage={SolutionDataConstant.ACRPharmLaunch.ContactUs.contactUsBGImage} 
        quote={SolutionDataConstant.ACRPharmLaunch.ContactUs.quote} 
      />
    </>
  );
}

export default ACRPharmLaunch;
