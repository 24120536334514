const SolutionHero = ({ image, title, highlight, description }) => {
  return (
    <div className={`hero relative  bg-cover ${image} bg-center`}>
      <div className="flex flex-col pt-10 md:pt-28 pb-10 md:pb-20 pl-5 md:pl-0 pr-5">
        <div>
          <h1 className="text-white uppercase text-xl">Solutions <strong>| {title}</strong></h1>
        </div>
        <div className="mt-20">
          <p className="text-white text-3xl max-w-2xl md:text-6xl md:max-w-5xl">
            <span className="inline-block pb-3 overflow-ellipsis overflow-hidden max-w-5xl">
              {highlight}
            </span>
          </p>
          <span className="absolute bg-green-500 h-2 w-16"></span>
        </div>
        <div className="mt-20">
          <p className="text-justify text-white font-light text-2xl max-w-4xl md:text-3xl md:max-w-6xl">
            <span className="inline-block pb-3 overflow-ellipsis overflow-hidden max-w-6xl">
              {description}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SolutionHero;
