import BasicCard from "components/common/basic-card";

function IndustryCard({ cardData }) {
  return (
    <>
      <div className="industry-card flex justify-center">
        <div className="max-h-50 overflow-y-auto max-w-screen-xl mx-auto w-full p-4 justify-center">
          <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4 md:gap-4">
            {cardData.map((card, index) => (
              <BasicCard
                key={index}
                title={card.title}
                description={card.description}
                bulletPoints={card.bulletPoints}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default IndustryCard;
