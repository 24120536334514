import React from "react";

const ServiceCards = ({ bgColor, cardsBgColor, cardContent, description, title }) => {
  return (
        <section className={`p-20 sm:p-16 ${bgColor}`}>
          <div className="w-full flex flex-col ">
            <h2 className="m-0 mb-10 font-light text-white text-blue text-2xl md:text3xl items-center pr-5 text-center">
              <strong>Solutions we create | </strong>{title}
            </h2>
            <p className="m-0 text-base text-white mb-5 text-center max-w-480">
              {description}
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
            {cardContent.map((card, index) => (
              <div key={index} className={`p-45 ${cardsBgColor} max-w-2xl flex flex-col`}>
                {/* <figure className="w-20 h-20 p-2">
                  {card.Icon}
                </figure> */}
                <h3 className="m-0 mb-4 text-white font-normal pl-5 pt-5 text-2xl text-center">
                  {card.Name}
                </h3>
                <p className="text-justify m-0 pl-5 pr-5 pb-5 text-white font-base text-center flex-1">
                  {card.Description}
                </p>
              </div>
            ))}
          </div>
        </section>
      );
};
export default ServiceCards;