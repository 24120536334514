import React from "react";
import { ReactComponent as ToolsAndTechIcon } from "assets/icons/landing/tools_and_tech.svg";
import { ReactComponent as FortuneClientsIcon } from "assets/icons/landing/fortune-clients.svg";
import { ReactComponent as ProjectsCompletedIcon } from "assets/icons/landing/projects-completed.svg";
import ThemeContext from "contexts/ThemeContext";
import { ThemeConstants } from "constants/theme";

const StatsSection = () => {
  const { theme } = React.useContext(ThemeContext);
  return (
    <section
      className={`flex flex-col mx-auto overflow-hidden py-20 text-center bg-cover  ${
        theme === ThemeConstants.DarkThemeName
          ? "bg-landing-company-stats-black"
          : "bg-landing-company-stats-light"
      }`}
    >
      <div className="font-beckman font-black text-3xl flex flex-col items-center justify-center">
        <div className="w-fit flex flex-col justify-center items-center"></div>
      </div>
      <div className="stats shadow rounded-3xl flex flex-col md:grid md:rounded-3xl">
        <div className="stat gap-x-0 md:gap-x-4">
          <div className="stat-figure w-20 h-20">
            <ToolsAndTechIcon
              fill={theme === ThemeConstants.DarkThemeName && "#cfcfcf"}
            />
          </div>
          <div className="stat-title font-bold text-xl">Data Tools & Tech</div>
          <div className="stat-value mt-2">300+</div>
          <div className="stat-desc mt-2">21% more than competitors</div>
        </div>

        <div className="stat">
          <div className="stat-figure w-20 h-20">
            <FortuneClientsIcon
              fill={theme === ThemeConstants.DarkThemeName && "#cfcfcf"}
            />
          </div>
          <div className="stat-title font-bold text-xl">
            Fortune 500 Clients
          </div>
          <div className="stat-value">10+</div>
          <div className="stat-desc"></div>
        </div>

        <div className="stat">
          <div className="stat-figure w-20 h-20">
            <ProjectsCompletedIcon
              fill={theme === ThemeConstants.DarkThemeName && "#cfcfcf"}
            />
          </div>
          <div className="stat-desc"></div>
          <div className="stat-value">50+</div>
          <div className="stat-title font-bold text-xl">Projects Completed</div>
        </div>
      </div>
    </section>
  );
};

export default StatsSection;
