import React from "react";
import ThemeContext from "contexts/ThemeContext";
import { ThemeConstants } from "constants/theme";
import Accordion from "components/common/accordion";
import CareerPageConstant from "constants/career-page";

const CareersInfo = () => {
  const { theme } = React.useContext(ThemeContext);

  return (
    <>
      <section className={`flex flex-col mx-auto overflow-hidden px-80 py-10`}>
        <div className='font-beckman font-black text-3xl flex flex-col items-center justify-center'>
          <div className='w-fit flex flex-col justify-center items-center'>
            <span className={`${theme === ThemeConstants.DarkThemeName ? "" : "text-blue-900 bg-gray-50 bg-opacity-25 p-1 rounded-full"}`}>Current Open Positions</span>
            {/* Styled bottom border */}
            <div className='flex flex-row justify-center items-center w-full mt-2'>
              <div className={`border-b-2 border-solid ${theme === ThemeConstants.DarkThemeName ? "border-[#a6adba]" : "border-[#1f2937]"} w-full`}></div>
              <div className={`border-b-4 border-solid ${theme === ThemeConstants.DarkThemeName ? "border-[#a6adba]" : "border-[#1f2937]"} w-full`}></div>
              <div className={`border-b-2 border-solid ${theme === ThemeConstants.DarkThemeName ? "border-[#a6adba]" : "border-[#1f2937]"} w-full`}></div>
            </div>
          </div>
        </div>
        <br />

        <div className={`py-10`}>
          <Accordion
            data={CareerPageConstant.OpenPositions.map(({ applicationProcedure, locationInfo, positionType, requirements, responsibilities, title }) => {
              return {
                title: `${title}: ${positionType}`,
                content: (
                  <div>
                    <div className='card card-side  shadow-xl flex-col md:flex-row'>
                      <div className='card-body'>
                        <p className='text-justify'>
                          <div>
                            <span className='text-xl font-bold'>Description:</span>
                            <br />
                            <div className='p-4'>
                              {responsibilities.map((responsibility) => (
                                <div className='flex'>
                                  <div className='inline'>
                                    <li></li>
                                  </div>
                                  <div className='inline'>{responsibility}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div>
                            <span className='text-xl font-bold'>Requirements: </span>
                            <br />
                            <div className='p-4'>
                              {requirements.map((requirement) => (
                                <div className='flex'>
                                  <div className='inline'>
                                    <li></li>
                                  </div>
                                  <div className='inline'>{requirement}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div>
                            <span className='text-xl font-bold'>Application Procedure:</span>
                            <br />
                            <div className='p-4'>
                              Mail resumes ATTN: <u>{applicationProcedure}</u>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                ),
              };
            })}
          />
        </div>
      </section>
      <div class='justify-center flex items-center flex-col'></div>
    </>
  );
};

export default CareersInfo;
