import React from "react";

const ContactUsForm = ({ backgroundImage, quote }) => {
  return (
    <>
      <div className={`pt:10 md:pt-16 pl:10 md:pl-20 pb-16 pr-5 bg-cover bg-center ${backgroundImage}`}>
        <p className="text-white text-2xl md:text-4xl mt-3 mb-9">
          Tell us more about your project.
        </p>
        <p className="text-white mt-4 font-light text-2xl max-w-3xl md:max-w-4xl">
            <span className="text-justify pb-3 overflow-ellipsis overflow-hidden max-w-3xl md:max-w-4xl">
              {quote}
            </span>
        </p>
      </div>
    </>
  );
};

export default ContactUsForm;
