import { ReactComponent as ACRPharmLaunchIcon } from "assets/icons/landing/acr-pharm-launch.svg";
import { ReactComponent as ACRAcceleratorIcon } from "assets/icons/landing/acr-accelerator.svg";
import { ReactComponent as ClinicalDataIcon } from "assets/icons/landing/clinical-data-management.svg";
import { ReactComponent as Chatbot } from "assets/icons/landing/chatbot.svg";
import { ReactComponent as ReasonIcon1 } from "assets/icons/landing/why-chose-us-1.svg";
import { ReactComponent as ReasonIcon2 } from "assets/icons/landing/why-chose-us-2.svg";
import { ReactComponent as ReasonIcon3 } from "assets/icons/landing/why-chose-us-3.svg";
import { ReactComponent as ReasonIcon4 } from "assets/icons/landing/why-chose-us-4.svg";

const SolutionSectionConstant = {
  SolutionCard: [
    {
      title: "ACR PharmLaunch",
      icon: ACRPharmLaunchIcon,
      content: "Streamline your pharma drug launch planning with PharmLaunch - the cutting edge software solution designed to optimize efficiency, enhance collaboration, and drive successful product launches.",
      learnMorePath: "/solution/acr-pharmlaunch",

    },
    {
      title: "ACR Accelerator",
      icon: ACRAcceleratorIcon,
      content: "Accelerate your study design journey with Clinical Operations Study Viability an Al-assisted solution that empowers clinical operations, technology, and innovation teams to swiftly analyze patient populations, assess site...",
      learnMorePath: "/solution/acr-accelerator",

    },
    {
      title: "Clinical Data Management",
      icon: ClinicalDataIcon,
      content: "Our Clinical Data Management services are tailored to meet the complex and critical needs of the healthcare and pharmaceutical industries. We provide end-to-end solutions for collecting, cleaning, and managing clinical trial data, ensuring regulatory compliance and data accuracy. With a focus on quality and efficiency, we enable healthcare organizations to accelerate drug development, make informed decisions, and ultimately improve patient outcomes.",
      learnMorePath: "/solution/clinical-data-management",

    },
    {
      title: "Generative AI",
      icon: Chatbot,
      content: "Our Generative AI services harness the power of cutting-edge artificial intelligence to create dynamic and engaging chatbot experiences. Whether it's customer support, sales, or virtual assistants, our AI chatbots are designed to understand and respond to user inquiries naturally. With advanced natural language processing and machine learning, we help businesses automate conversations, improve user engagement, and deliver personalized interactions that drive customer satisfaction and operational efficiency.",
      learnMorePath: "/solution/generative-chatbot",

    },
  ],

  WhyUsReasons: [
    {
      icon: ReasonIcon1,
      title: "Robust end to end Digital Capabilities",
    },
    {
      icon: ReasonIcon2,
      title: "Competitive Pricing",
    },
    {
      icon: ReasonIcon3,
      title: "100% Client Retention Record",
    },
    {
      icon: ReasonIcon4,
      title: "Deep Domain & Technical Expertise",
    },
  ],
};

export default SolutionSectionConstant;
