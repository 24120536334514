import React from "react";
import ThemeContext from "contexts/ThemeContext";
import { ThemeConstants } from "constants/theme";

const MissionCards = () => {
  const { theme } = React.useContext(ThemeContext);

  console.log('theme', theme)
  return (
    <>
      <section
        className={`flex flex-col mx-auto overflow-hidden px-80 py-10`}
      >
        <div className="font-beckman font-black text-3xl flex flex-col items-center justify-center">
          <div className="w-fit flex flex-col justify-center items-center">
            <span
              className={`${
                theme === ThemeConstants.DarkThemeName
                  ? ""
                  : "text-blue-900 bg-gray-50 bg-opacity-25 p-1 rounded-full"
              }`}
            >
              Our Mission
            </span>
            <div className="flex flex-row justify-center items-center w-full mt-2">
              <div
                className={`border-b-2 border-solid ${
                  theme === ThemeConstants.DarkThemeName ? "border-[#a6adba]" : "border-[#1f2937]"
                } w-full`}
              ></div>
              <div
                className={`border-b-4 border-solid ${
                  theme === ThemeConstants.DarkThemeName ? "border-[#a6adba]" : "border-[#1f2937]"
                } w-full`}
              ></div>
              <div
                className={`border-b-2 border-solid ${
                  theme === ThemeConstants.DarkThemeName ? "border-[#a6adba]" : "border-[#1f2937]"
                } w-full`}
              ></div>
            </div>
          </div>
        </div> 
        <br />
        <p className="text-1xl">
          "At ACR Technology, our unwavering commitment is to deliver technology solutions and professional outsourcing services of the highest quality. We are dedicated to ensuring that our clients derive tangible business value from our offerings, enabling them to successfully realize their strategic goals and initiatives."
        </p>
      </section>
      <section
        className={`flex flex-col mx-auto overflow-hidden px-80 py-10`}
      >
        <div className="font-beckman font-black text-3xl flex flex-col items-center justify-center">
          <div className="w-fit flex flex-col justify-center items-center">
            <span
              className={`${
                theme === ThemeConstants.DarkThemeName
                  ? ""
                  : "text-blue-900 bg-gray-50 bg-opacity-25 p-1 rounded-full"
              }`}
            >
              Our Values
            </span>
            <div className="flex flex-row justify-center items-center w-full mt-2">
              <div
                className={`border-b-2 border-solid ${
                  theme === ThemeConstants.DarkThemeName ? "border-[#a6adba]" : "border-[#1f2937]"
                } w-full`}
              ></div>
              <div
                className={`border-b-4 border-solid ${
                  theme === ThemeConstants.DarkThemeName ? "border-[#a6adba]" : "border-[#1f2937]"
                } w-full`}
              ></div>
              <div
                className={`border-b-2 border-solid ${
                  theme === ThemeConstants.DarkThemeName ? "border-[#a6adba]" : "border-[#1f2937]"
                } w-full`}
              ></div>
            </div>
          </div>
        </div> 
        <br />
        </section>
    </>
  );
};

export default MissionCards;
