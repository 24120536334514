import IndustryCard from "components/industry/industry-card";
import IndustryHero from "components/industry/industry-hero";
import IndustryDataConstant from "constants/industry";
import React from "react";
import IndustryClientHighlight from "components/industry/client-highlight";
import WhyUsSection from "components/landing/why-us";
import JoinUsSection from "components/common/join-us";
import { useEffect } from "react";

function BankingFinancial() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className="bg-cover bg-ind-banking">
      <IndustryHero title={IndustryDataConstant.BankingFinancial.Hero.title} description={IndustryDataConstant.BankingFinancial.Hero.description} />
      <IndustryCard cardData={IndustryDataConstant.BankingFinancial.Cards} />
    </div>
    <IndustryClientHighlight
      ImageSrc={IndustryDataConstant.BankingFinancial.ClientHighlight.imageSrc}
      Description={IndustryDataConstant.BankingFinancial.ClientHighlight.description}
      bulletPoints={IndustryDataConstant.BankingFinancial.ClientHighlight.bulletPoints}
    />
    <WhyUsSection />
    <JoinUsSection />
    </>
  );
}

export default BankingFinancial;
