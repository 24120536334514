import React from "react";
import ThemeContext from "contexts/ThemeContext";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LandingPage from "pages/landing";
import { ThemeConstants } from "constants/theme";
import RootLayout from "pages/root-layout";
import NotFound from "pages/not-found";

/* About Us */
import OurMission from "pages/our-mission";

/* Services */
import ArtificialIntelligence from "pages/service/artificial-intelligence";
import BusinessIntelligence from "pages/service/business-intelligence";
import DataEngineering from "pages/service/data-engineering";
import ApplicationDevelopment from "pages/service/app-dev";
import ProjectManagement from "pages/service/project-management";
import Staffing from "pages/service/staffing";

/* Solutions */
import ACRAccelerator from "pages/solution/acr-accelerator";
import ACRPharmLaunch from "pages/solution/acr-pharmlaunch";
import ClinicalDataManagement from "pages/solution/clinical-data-management";
import ClinicalOperationsViability from "pages/solution/clinical-operations-viability";
import GenerativeChatbot from "pages/solution/generative-chatbot";

/* Industries */
import BankingFinancial from "pages/industry/banking-financial";
import HiTech from "pages/industry/hi-tech";
import LifeSciences from "pages/industry/life-sciences";
import RetailCPG from "pages/industry/retail";
import TravelHospitality from "pages/industry/travel-hospitality";
import Careers from "pages/careers";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { index: true, element: <LandingPage /> },

      /* About Us */
      { path: "/about-us", element: <OurMission /> },

      /* Industries */
      { path: "/industry/banking-financial", element: <BankingFinancial /> },
      { path: "/industry/hi-tech", element: <HiTech /> },
      { path: "/industry/life-sciences", element: <LifeSciences /> },
      { path: "/industry/retail", element: <RetailCPG /> },
      { path: "/industry/travel-hospitality", element: <TravelHospitality /> },

      /* Services */
      { path: "/service/artificial-intelligence", element: <ArtificialIntelligence /> },
      { path: "/service/business-intelligence", element: <BusinessIntelligence />, },
      { path: "/service/data-engineering", element: <DataEngineering /> },
      { path: "/service/app-dev", element: <ApplicationDevelopment /> },
      { path: "/service/project-management", element: <ProjectManagement /> },
      { path: "/service/staffing", element: <Staffing /> },

      /* Solutions */
      { path: "/solution/acr-accelerator", element: <ACRAccelerator /> },
      { path: "/solution/acr-pharmlaunch", element: <ACRPharmLaunch /> },
      { path: "/solution/clinical-data-management", element: <ClinicalDataManagement /> },
      { path: "/solution/clinical-operations-viability", element: <ClinicalOperationsViability /> },
      { path: "/solution/generative-chatbot", element: <GenerativeChatbot /> },

      /* About Us */
      { path: "/careers", element: <Careers /> },

      { path: "*", element: <NotFound /> },
    ],
  },
]);

function App() {
  const [theme, setTheme] = React.useState(
    ThemeConstants.LightThemeName
    // window.matchMedia("(prefers-color-scheme: dark)").matches
    //   ? ThemeConstants.DarkThemeName
    //   : ThemeConstants.LightThemeName
  );
  // initially set the theme and "listen" for changes to apply them to the HTML tag

  React.useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) => {
        const newIsDark = e.matches;
        if (newIsDark) {
          setTheme(ThemeConstants.DarkThemeName);
        } else {
          setTheme(ThemeConstants.LightThemeName);
        }
      });
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      });
    });
  }, []);

  React.useEffect(() => {
    document.querySelector("html").setAttribute("data-theme", theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme, contentPadding: 0 }}>
      <RouterProvider router={routes}/>
    </ThemeContext.Provider>
  );
}

export default App;
