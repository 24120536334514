import React from 'react';

const WorkForYouSection = ({ bg, description, bulletPoints }) => {
  return (
    <section className={`py-12 lg:py-8 bg-cover ${bg}`} title="Stages/Steps-2">
      <h2 className="text-4xl font-headline2 text-white font-bold mb-5">
        How We Work For You
      </h2>
      <p className="text-justify text-light font-normal font-normal text-white mb-5 max-w-2xl">
        {description}
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 text-white gap-6 md:gap-10">
        {bulletPoints.map((bp, index) => (
          <div key={index} className="flex flex-row p-0 lg:p-0">
            <div className="w-10 h-10 bg-gray-700 flex items-center justify-center rounded-full mr-4 text-light text-sm">
              {index+1}
            </div>
            <div className="flex-1">
              <h3 className="text-2xl font-normal text-light">{bp.title}</h3>
              <p className="text-justify text-base font-normal pt-2 text-light">{bp.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WorkForYouSection;
