import React from "react";
import { Link } from "react-router-dom";
import Accordion from "components/common/accordion";
import SolutionSectionConstant from "constants/landing-page";

const SolutionsSection = () => {
  return (
    <section className={`flex flex-col mx-auto overflow-hidden py-20 bg-cover bg-landing-solution`}>
      <div className='text-center font-beckman text-white text-3xl md:text-5xl flex flex-col items-center justify-center'>
        <div className={`w-fit flex flex-col justify-center items-center}`}>
          Our Solutions
          <div className='flex flex-row justify-center items-center w-full mt-2'>
            <div className={`border-b-2 border-solid border-white w-full`}></div>
            <div className={`border-b-4 border-solid border-white w-full`}></div>
            <div className={`border-b-2 border-solid border-white w-full`}></div>
          </div>
        </div>
      </div>
      <div className={`py-10`}>
        <Accordion
          data={SolutionSectionConstant.SolutionCard.map(({content, icon: Icon, learnMorePath, title}) => {
            return {
              title,
              content: (
                <div>
                  <div className='card card-side  shadow-xl flex-col md:flex-row'>
                    <figure>
                      <Icon fill="#cfcfcf" className='sm:w-20 sm:h-20 w-40 h-40 md:w-80 md:h-80' />
                    </figure>
                    <div className='card-body'>
                      <p className="text-justify">
                       {content}
                      </p>
                      <div className='card-actions justify-end mt-4 md:mt-0'>
                        <Link to={learnMorePath}>
                          <button className='btn bg-white font-poppins text-black'>
                            Learn More
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ),
            };
          })}
        />
      </div>
    </section>
  );
};

export default SolutionsSection;
