const CareerPageConstant = {
  OpenPositions: [
    {
      title: "Sofware Developer (Data Virtualization) F/T",
      description: "",
      responsibilities: [
        "Develop and maintain Enterprise Data Models for Enterprise Class Data Initiatives.",
        "Collect, analyzes and lead business requirements gathering sessions with business and technical resources for the data virtualization system.",
        "Design, develop, document, test, monitor, troubleshoot and administrate our data environment using ETL / Data Virtualization / BI Reporting/ Big Data ingestion tool sets.",
        "Communicate and accurately document all architectural decisions, plans, goals, functional requirements, and open items and issues to key stakeholders, including management, and development teams.",
        "Write and optimize in-application SQL statements for data access & retention in Denodo Platform.",
        "Perform regular database management duties including backups and audits to ensure SQL databases remain accessible, secure and stable.",
        "Create Denodo view with different data sources including SAP HANA, SQL Server and custom.",
        "Manage data & log files to ensure they are stored separately, eliminate any instances of file or index fragmentation & check for data corruption.",
        "Improve efficiency and performance tuning of existing and new views in the Denodo Platform.",
        "Create new and modify existing reports utilizing the Denodo Platform per customer specifications.",
        "Develop conceptual and logical information data models within the context of the Denodo.",
        "Develop efficient mapping of data sources, data movements, and analytics ensuring data quality and integrity.",
        "Promotion between environments, maintenance of all environments",
        "Connecting to data sources (JDBC / ODBC / JSON / LDAP sources).",
        "Develop and enforce data modeling standards and best practices.",
        "Contribute to the designing, developing, modifying, testing, and debugging of various reports within Denodo Platforms to meet customer requirements.",
        "Collaborate with customers and colleagues."
      ],
      positionType: "Full-Time",
      requirements: ["Education : Master's degree in Computer Science or any Engineering."],
      locationInfo: "Cary, NC but relocation possible.",
      applicationProcedure: "HR, ACR Technology LLC. 2500 Regency Pkwy, Cary, NC 27518.",
      postedDate: "",
    },
  ],
};

export default CareerPageConstant