import React from "react";
import ThemeContext from "contexts/ThemeContext";
import { ThemeConstants } from "constants/theme";
import SolutionSectionConstant from "constants/landing-page";

const WhyUsSection = () => {
  const { theme } = React.useContext(ThemeContext);
  return (
    <section className='items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:padding-bottom-24 lg:px-6'>
      <div className='col-span-2 mb-8'>
        <p className='text-2xl font-semibold'>Why Choose Us?</p>
        <h2 className='mt-3 mb-4 text-3xl font-extrabold tracking-tight md:text-3xl pt-6 space-y-4 border-t'>Trusted by Industry Leaders</h2>
        <p className='font-light sm:text-xl text-justify'>
          Partner with us for comprehensive IT services and consulting expertise that drives innovation, delivers measurable results, and propels your business forward.
        </p>
        <div></div>
      </div>
      <div className='col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-8 md:space-y-0'>
        {SolutionSectionConstant.WhyUsReasons.map(({ icon: ReasonIcon, title }) => (
          <div className='flex flex-col justify-center items-center md:block'>
            <ReasonIcon className='w-14 h-14 mb-2 md:w-20 md:h-20' fill={theme === ThemeConstants.DarkThemeName && "#cfcfcf"}></ReasonIcon>
            <h3 className='md:mb-2 text-lg md:text-xl font-semibold'>{title}</h3>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhyUsSection;
