import React from "react";

function ClientHighlight({ Title, ImageSrc, Description, bulletPoints }) {

  return (
    <>
    <div className="client-highlight-image-description-container font-poppins">
      <div className="hidden client-highlight-image md:flex">
        <img src={ImageSrc} alt="highlight"/>
      </div>
      <div className="client-highlight-image-description">
        <div className="client-highlight-image-description-scrollable">
        <div className='text-center font-poppins font-black text-3xl md:text-5xl flex flex-col items-center justify-center'>
          <div className={`w-fit flex flex-col justify-center font-poppins `}>
            {Title}
            <div className='flex flex-row w-full mt-2'>
              <div className={`border-b-2 border-solid "border-[#1f2937]"} w-full`} />
              <div className={`border-b-4 border-solid "border-[#1f2937]"} w-full`} />
              <div className={`border-b-2 border-solid "border-[#1f2937]"} w-full`} />
            </div>
          </div>
      </div>
      <div className="justify-center flex items-center md:hidden">
        <img src={ImageSrc} alt="highlight" />
      </div>
      <div className={`flex flex-col justify-center items-center pt-10`}>
        <p className="text-justify font-poppins">
          {Description}
        </p>
      </div>
      </div>
      <div className={`flex flex-col pt-1`}>
        <ul className="px-6 py-4 pt-4 font-poppins">
          {
            bulletPoints.map((point, index) => (
              <li key={index} className="text-gray-500 text-base list-disc pt-1 list-outside list-square font-poppins">
                {point}
              </li>
            ))
          }
        </ul>
      </div>
      </div>
    </div>
    </>
  );
}

export default ClientHighlight;