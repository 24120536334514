import SiteMap from "components/common/SiteMap";
import Navbar from "components/common/Navbar";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";

const RootLayout = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Navbar />
      <main>
        <Outlet />
      </main>
      <SiteMap />
    </>
  );
};

export default RootLayout
