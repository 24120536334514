import React from "react";
import ThemeContext from "contexts/ThemeContext";
import { ThemeConstants } from "constants/theme";

const HeroSection = ({ primaryText, secondaryText, lightImg, darkImg }) => {
  const {theme} = React.useContext(ThemeContext)
  return (
      <section className={`flex flex-col mx-auto ${theme === ThemeConstants.DarkThemeName ? darkImg : lightImg} bg-cover`}>
        <div
          className={`inset-0
          max-w-7xl flex flex-row items-start
          justify-between py-40`}
        >
          <div>
            <div
              className={`font-inter uppercase bg-transparent text-4xl md:text-5xl text-yellow-700`}
              data-theme={`${theme === ThemeConstants.LightThemeName ? 'luxury' : theme}`}
            >
              {primaryText}
            </div>
            <div
              className="text-white text-4xl md:text-5xl font-inter
                font-extrabold mt-2 font-light"
            >
              {secondaryText}
            </div>
          </div>
          <div
            className="w-screen"
          ></div>
        </div>
      </section>
  );
};

export default HeroSection;
