const ServiceHero = ({ image, title, description }) => {
  return (
    <div className="relative">
      <div className={`h-auto sm:h-screen bg-cover bg-center flex items-center pl-2 sm:pl-48 ${image}`}>
        <div>
          <h1 className="text-4xl sm:text-8xl text-white text-center">{title}</h1>
          <h1 className="text-2xl sm:text-4xl pt-3 sm:pt-6 max-w-6xl font-light text-white text-center">{description}</h1>
        </div>
      </div>
    </div>
  );
};

export default ServiceHero;
