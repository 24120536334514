import React from "react";
import Card from "components/common/card";
import techIcon from "assets/icons/landing/tech.svg";
import bankIcon from "assets/icons/landing/ind-bank.svg";
import travelIcon from "assets/icons/landing/ind-travel.svg";
import retailIcon from "assets/icons/landing/ind-retail.svg";
import lfIcon from "assets/icons/landing/ind-lf.svg";

const SuccessSection = () => {
  return (
    <section className={`flex flex-col mx-auto overflow-hidden py-20 bg-cover bg-landing-industry`}>
      <div className='font-inter font-black sm:text-1xl text-3xl flex flex-col items-center justify-center'>
        <div className='w-fit flex flex-col justify-center items-center'>
          <span className="text-white bg-gray-50 bg-opacity-10 p-1 rounded-full">
            To go the extra mile, we use industry specific sophisticated solutions
          </span>
          <div className='flex flex-row justify-center items-center w-full mt-2'>
            <div className={`border-b-2 border-solid "border-[#fff]" w-full`}></div>
            <div className={`border-b-4 border-solid "border-[#fff]" w-full`}></div>
            <div className={`border-b-2 border-solid "border-[#fff]" w-full`}></div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-2  mt-5">
          <Card cardImage={lfIcon} cardRedirect='/industry/life-sciences' cardMessage='Harness the full potential of your life sciences data with the profound domain knowledge and technical proficiency of our specialists.' cardTitle='Life Sciences' containerClass='flex-1 my-2 md:my-0'/>
          <Card cardImage={bankIcon} cardRedirect='/industry/banking-financial' cardMessage='Leverage the deep domain expertise and technical proficiency of our professionals to empower your financial data.' cardTitle='Banking & Financial' containerClass='flex-1 my-2 md:my-0'/>
          <Card cardImage={retailIcon} cardRedirect='/industry/retail' cardMessage='Maximize the potential of your retail operations with the deep domain knowledge and technical proficiency of our experts.' cardTitle='Retail' containerClass='flex-1 my-2 md:my-0'/>
          <Card cardImage={travelIcon} cardRedirect='/industry/travel-hospitality' cardMessage='Unleash the power of your automotive data through the technical proficiency and profound industry insight of our specialists.' cardTitle='Automative Travel' containerClass='flex-1 my-2 md:my-0'/>
          <Card cardImage={techIcon} cardRedirect='/industry/hi-tech' cardMessage='Amplify your high-tech innovations with the deep technical proficiency and industry expertise of our specialists.' cardTitle='Hi-Tech'/>
      </div>
    </section>
  );
};

export default SuccessSection;
