import ServiceHero from "components/service/service-hero";
import ServiceDataConstant from "constants/services";
import React,{ useEffect } from "react";
import ContactUsForm from "pages/contact-us";
import ServiceCards from "components/service/service-cards";
import WhyUsSection from "components/landing/why-us";
import WorkForYouSection from "components/common/work-for-you";
import ClientHighlight from "components/common/client-highlight";

function ProjectManagement() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <ServiceHero
          image={ServiceDataConstant.ProjectManagement.Hero.image}
          title={ServiceDataConstant.ProjectManagement.Hero.title}
          description={ServiceDataConstant.ProjectManagement.Hero.description} 
      />
      <ServiceCards 
        title={ServiceDataConstant.ProjectManagement.ServiceCards.title}
        description={ServiceDataConstant.ProjectManagement.ServiceCards.description}
        bgColor={ServiceDataConstant.ProjectManagement.ServiceCards.bgColor}
        cardsBgColor={ServiceDataConstant.ProjectManagement.ServiceCards.cardsBgColor}
        cardContent={ServiceDataConstant.ProjectManagement.ServiceCards.Content}
      />
      <ClientHighlight
        Title={ServiceDataConstant.ProjectManagement.ClientHighlight.title}
        ImageSrc={ServiceDataConstant.ProjectManagement.ClientHighlight.image}
        Description={ServiceDataConstant.ProjectManagement.ClientHighlight.description}
        bulletPoints={ServiceDataConstant.ProjectManagement.ClientHighlight.bulletPoints}
      />
      <WorkForYouSection 
        bg={ServiceDataConstant.ProjectManagement.WorkForYou.bg}
        description={ServiceDataConstant.ProjectManagement.WorkForYou.description}
        bulletPoints={ServiceDataConstant.ProjectManagement.WorkForYou.bulletPoints}
      />
      <WhyUsSection />
      <ContactUsForm
        backgroundImage={ServiceDataConstant.ProjectManagement.ContactUs.contactUsBGImage}
        quote={ServiceDataConstant.ProjectManagement.ContactUs.quote}
      />
    </>
  );
}

export default ProjectManagement;
