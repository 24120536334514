import DEImage from "assets/backgrounds/service/ch-de.png";
import BIImage from "assets/backgrounds/service/ch-bi.jpg";
import AIImage from "assets/backgrounds/service/ch-ai.jpg";
import PMImage from "assets/backgrounds/service/ch-pm.jfif";
import ADImage from "assets/backgrounds/service/ch-ad.jpg";
import StaffingImage from "assets/backgrounds/service/ch-staffing.jpg";

const ServiceDataConstant = {
  DataEngineering: {
    Hero: {
      title: 'Data Engineering',
      description: `ACR provides an all-encompassing data engineering portfolio seamlessly integrated within its cloud applications, supported by a top-tier data engineering infrastructure and incorporating cutting-edge data engineering innovations.`,
      image: 'bg-service-data-engineering',
    },
    ServiceCards: {
      title: 'Data Engineering',
      description: 'Unlock the Power of Data with Our Engineering Solutions!',
      bgColor: 'bg-gray-800',
      cardsBgColor: '',
      Content: [
        {
          Icon: '',
          Name: 'Data Integration Hub',
          Description: 'Seamlessly integrate data from various sources into a unified platform, enhancing business agility, decision-making, and efficiency by breaking down data silos and driving insights.',
        },
        {
          Icon: '',
          Name: 'Real-time Data Streaming',
          Description: 'Achieve real-time data processing and analytics for instant insights, staying ahead of the competition, optimizing operations, and improving customer experiences by reacting to market changes as they happen.',
        },
        {
          Icon: '',
          Name: 'Data Quality Assurance',
          Description: 'Ensure data accuracy, consistency, and compliance with automated quality checks, mitigating costly errors and regulatory risks, leading to better-informed decisions and improved customer trust.',
        },
        {
          Icon: '',
          Name: 'Data Warehousing and Analytics',
          Description: 'Centralize and analyze data for business intelligence and reporting, empowering data-driven decision-making, discovering hidden patterns, and gaining a competitive edge.',
        },
        {
          Icon: '',
          Name: 'ETL Automation',
          Description: 'Automate the extraction, transformation, and loading of data from diverse sources, saving time and resources, reducing errors, and accelerating data delivery for faster insights and action.',
        },
        {
          Icon: '',
          Name: 'Scalable Cloud Solutions',
          Description: 'Migrate data operations to the cloud for scalable, cost-effective, and flexible architectures, future-proofing your data infrastructure, reducing operational costs, and scaling seamlessly as your business grows.',
        },
      ],
    },
    ClientHighlight: {
      title: 'Our Benefits',
      image: DEImage,
      description: `ACR: Elevating Your Data Infrastructure with Precision, Efficiency, and Measurable Impact`,
      bulletPoints: [
        'Strategic Data Architecture: Tailored data strategies aligned with your business objectives.',
        'Expert Data Engineering Team: Access top data engineers and specialists.',
        'Tangible ROI: Data engineering solutions that deliver measurable impact on your operations.',
        'Customized Data Solutions: Tailored data infrastructure for your unique needs.',
        'Seamless Integration: Integration of data solutions into your existing systems with minimal disruption.',
        'Continuous Optimization: Ongoing data engineering support to ensure your infrastructure remains efficient and adaptable.',
      ],
    },
    WorkForYou: {
      bg: 'bg-svc-wfu-de',
      description: `Our core mission as a data engineering service provider is to engage closely with you, gain a deep understanding of your specific needs, and deliver high-quality data engineering solutions that not only align with your expectations but also drive your data-driven success.`,
      bulletPoints: [
        {
          title: 'Data Ecosystem Assessment',
          description: `We begin by conducting a meticulous evaluation of your existing data ecosystem, ensuring a thorough understanding of your data engineering requirements.`,
        },
        {
          title: 'Bespoke Data Engineering Plan',
          description: `Following the assessment, we develop a customized data engineering plan that details the project scope, cost estimate, and timeline, tailored precisely to meet your data management goals.`,
        },
        {
          title: 'Data Engineering Partnership Agreement',
          description: `Once you approve the plan, we proceed to formalize our partnership by signing a comprehensive agreement, clearly defining the project terms, roles, and expectations.`,
        },
        {
          title: 'Collaborative Data Transformation',
          description: `Our proficient data engineering team initiates project implementation, maintaining open lines of communication to keep you informed and engaged throughout the process. This guarantees your data engineering solution aligns perfectly with your expectations and objectives.`,
        },
      ],
    },
    ContactUs: {
      contactUsBGImage: 'bg-contact-us-data-eng',
      quote: `Whether you're tackling intricate data ecosystems, embarking on a data-driven transformation, or looking to enhance your data infrastructure, we're here to assist. Share your objectives, and together, we'll work to make your data engineering vision a reality.`,
    },
  },
  BusinessIntelligence: {
    Hero: {
      title: 'Business Intelligence',
      description: `ACR provides a comprehensive Business Intelligence (BI) portfolio expertly integrated with top-tier infrastructure. Our focus on innovation ensures that our BI solutions are at the forefront of industry standards to cater to your business needs.`,
      image: 'bg-service-biz-intel',
    },
    ServiceCards: {
      title: 'Business Intelligence',
      description: 'Illuminate Your Business with our Intelligence Solutions!',
      bgColor: 'bg-purple-950',
      cardsBgColor: '',
      Content: [
        {
          Icon: '',
          Name: 'Advanced Data Visualization',
          Description: 'Turn complex data into clear, interactive visualizations, empowering your teams to make informed decisions, spot trends, and take immediate action with intuitive data representations for deeper insights.',
        },
        {
          Icon: '',
          Name: 'Predictive Analytics',
          Description: 'Harness the power of data to forecast future trends and outcomes, staying ahead of the competition by making data-driven predictions and optimizing strategies for maximum efficiency and profitability.',
        },
        {
          Icon: '',
          Name: 'Self-Service BI Platforms',
          Description: ' Provide business users with easy-to-use tools for independent data exploration, boosting productivity and collaboration as your teams can access and analyze data without IT assistance, fostering quicker decision-making.',
        },
        {
          Icon: '',
          Name: 'BI Dashboards and Reporting',
          Description: 'Create customized dashboards and reports that deliver critical data at a glance, streamlining reporting, monitoring key performance indicators, and gaining a 360-degree view of your business for rapid and informed decisions.',
        },
        {
          Icon: '',
          Name: 'Data Warehousing for BI',
          Description: 'Build a robust data warehouse to store and manage BI data effectively, improving data accuracy and accessibility, enabling real-time decision-making, enhancing data-driven strategies, and ensuring compliance.',
        },
        {
          Icon: '',
          Name: 'Embedded BI Solutions',
          Description: 'Integrate BI capabilities directly into your applications or workflows, enhancing customer experiences and user engagement by providing in-context insights, making your offerings more competitive and valuable.',
        },
      ],
    },
    ClientHighlight: {
      title: 'Our Benefits',
      image: BIImage,
      description: `ACR: Illuminating Your Path to Success with Business Intelligence Excellence`,
      bulletPoints: [
        'Strategic Insights: Tailored BI strategies that align with your business objectives.',
        'Expert BI Team: Access top BI specialists and analysts.',
        'Tangible ROI: BI solutions that deliver actionable insights and a competitive edge.',
        'Customized Dashboards: Tailor-made data visualizations for your unique needs.',
        'Seamless Integration: Integration of BI solutions into your existing systems with ease.',
        'Continuous Analysis: Ongoing BI support to keep your insights relevant and impactful.',
      ],
    },
    WorkForYou: {
      bg: 'bg-svc-wfu-bi',
      description: `Our central commitment as a business intelligence service provider is to work closely alongside you, thoroughly comprehend your unique requirements, and deliver superior business intelligence solutions that not only meet your expectations but also empower your organization with actionable insights for informed decision-making.`,
      bulletPoints: [
        {
          title: 'Business Insights Assessment',
          description: `We commence by conducting an in-depth analysis of your organization's specific data needs, ensuring a comprehensive understanding of your business intelligence objectives.`,
        },
        {
          title: 'Tailored BI Strategy',
          description: `Following the assessment, we craft a customized business intelligence strategy that outlines the project scope, budget, and timeline, designed to meet your unique data-driven goals.`,
        },
        {
          title: 'BI Collaboration Agreement',
          description: `If the strategy aligns with your vision, we proceed to formalize our partnership by signing a comprehensive collaboration agreement, clearly delineating the terms, responsibilities, and project expectations.`,
        },
        {
          title: 'Interactive Business Intelligence Development',
          description: `Our skilled BI team initiates project development, maintaining continuous collaboration and communication to keep you engaged throughout the process. This ensures that your business intelligence solution not only meets your objectives but also exceeds your expectations.`,
        },
      ],
    },
    ContactUs: {
      contactUsBGImage: 'bg-contact-us-biz-intel',
      quote: `Whether you're unraveling complex market insights, embarking on a strategic business initiative, or striving to streamline your data-driven decision-making, we're your trusted partner. Share your goals, and let's collaborate to turn your aspirations into reality.`,
    },
  },
  ArtificialIntelligence: {
    Hero: {
      title: 'Artificial Intelligence',
      description: `ACR is at the forefront of delivering exceptional AI services, offering a diverse portfolio integrated within its cloud applications. Our AI infrastructure is unmatched, and we consistently introduce pioneering AI innovations to meet your specific needs.`,
      image: 'bg-service-ai',
    },
    ServiceCards: {
      title: 'Artificial Intelligence',
      description: 'Unleash the Power of AI with our Intelligent Solutions!',
      bgColor: 'bg-teal-900',
      cardsBgColor: '',
      Content: [
        {
          Icon: '',
          Name: 'Machine Learning Models',
          Description: 'Develop and deploy machine learning models to automate decision-making processes, enhancing operational efficiency, optimizing resource allocation, and making data-driven decisions with predictive and prescriptive insights.',
        },
        {
          Icon: '',
          Name: 'Natural Language Processing (NLP)',
          Description: 'Utilize NLP to extract valuable insights from unstructured text data, improving customer service, sentiment analysis, and content curation with the ability to understand and respond to human language.',
        },
        {
          Icon: '',
          Name: 'Computer Vision Solutions',
          Description: 'Leverage computer vision to interpret and analyze visual data from images and videos, enhancing security, automating quality control, and developing innovative applications by making sense of the visual world.',
        },
        {
          Icon: '',
          Name: 'Reinforcement Learning Algorithms',
          Description: 'Implement reinforcement learning for autonomous decision-making and optimization, empowering your systems to learn and adapt in real-time, leading to better resource allocation and continuous process improvement.',
        },
        {
          Icon: '',
          Name: 'AI-Driven Personalization',
          Description: 'Implement AI for personalized content recommendations and user experiences, boosting user engagement, increasing conversion rates, and fostering customer loyalty by delivering tailor-made experiences.',
        },
        {
          Icon: '',
          Name: 'AI-Enabled Chatbots',
          Description: 'Create AI-powered chatbots for seamless customer support and interaction, enhancing customer service, reducing response times, and increasing customer satisfaction by providing instant and round-the-clock assistance.',
        },
      ],
    },
    ClientHighlight: {
      title: 'Our Benefits',
      image: AIImage,
      description: `Empower Your Business with ACR: Pioneering AI Excellence, Crafting Your Path to Innovation, and Delivering Measurable Results.`,
      bulletPoints: [
        'Strategic AI Roadmaps: Tailored strategies for your business goals.',
        'Expert AI Team: Access top AI specialists and thought leaders.',
        'Proven ROI: AI solutions for measurable competitive advantages.',
        'Customized Solutions: Tailor-made AI applications for unique needs.',
        'Seamless Integration: AI that integrates smoothly into your systems.',
        'Continuous Support: Ongoing AI optimization for sustained success.',
      ],
    },
    WorkForYou: {
      bg: 'bg-svc-wfu-ai',
      description: `Our primary objective as an artificial intelligence service provider is to collaborate closely with you, comprehensively grasp your requirements, and deliver top-tier AI solutions that not only meet but exceed your expectations.`,
      bulletPoints: [
        {
          title: 'AI Needs Analysis',
          description: `We commence by conducting an in-depth analysis of your AI requirements, ensuring a precise understanding of your unique business needs and objectives.`,
        },
        {
          title: 'Tailored AI Proposal',
          description: `Following the needs analysis, we craft a custom-tailored AI proposal that outlines the project's scope, budget, and timeline, designed specifically to meet your AI objectives.`,
        },
        {
          title: 'AI Partnership Agreement',
          description: `If the proposal aligns with your vision, we proceed to formalize our partnership by signing a comprehensive agreement, clearly delineating the terms, responsibilities, and project expectations.`,
        },
        {
          title: 'Collaborative AI Development',
          description: `Our specialized AI team initiates project development, maintaining active communication to keep you engaged throughout the process. This ensures that your AI solution is not only on track but also aligned precisely with your expectations and objectives.`,
        },
      ],
    },
    ContactUs: {
      contactUsBGImage: 'bg-contact-us-ai',
      quote: `Whether you're delving into intricate AI applications, embarking on AI-driven innovation, or aiming to optimize your AI strategy, we're here to assist. Share your objectives, and together, we'll turn your AI vision into a reality.`,
    },
  },
  ProjectManagement: {
    Hero: {
      title: 'Project Management',
      description: `ACR provides a comprehensive Project Management portfolio with a powerful infrastructure, designed to propel your projects to success. Our commitment to innovation ensures that our project management solutions are always at the cutting edge, tailored to meet your specific business needs.`,
      image: 'bg-service-proj-mgm',
    },
    ServiceCards: {
      title: 'Project Management',
      description: 'Elevate Project Success with our Management Solutions!',
      bgColor: 'bg-slate-950',
      cardsBgColor: '',
      Content: [
        {
          Icon: '',
          Name: 'Project Planning and Scheduling',
          Description: 'Streamline project planning and scheduling for on-time and within-budget delivery, improving project efficiency, reducing delays, and enhancing resource utilization with well-structured project timelines.',
        },
        {
          Icon: '',
          Name: 'Task and Team Collaboration',
          Description: 'Enable seamless task assignment and team collaboration for improved project coordination, enhancing team productivity, communication, and accountability, ensuring projects run smoothly.',
        },
        {
          Icon: '',
          Name: 'Resource Allocation and Management',
          Description: 'Efficiently allocate and manage resources to optimize project performance, maximizing resource utilization, minimizing overallocation, and improving overall project profitability.',
        },
        {
          Icon: '',
          Name: 'Project Reporting and Analytics',
          Description: 'Generate detailed project reports and analytics for informed decision-making, gaining real-time insights into project progress, risks, and opportunities, allowing for timely adjustments and improved project outcomes.',
        },
        {
          Icon: '',
          Name: 'Portfolio Management Solutions',
          Description: `Manage a portfolio of projects with tools to prioritize and align with business goals, ensuring strategic alignment, prioritizing high-impact projects, and achieving a holistic view of your organization's projects for maximum efficiency.`,
        },
        {
          Icon: '',
          Name: 'Risk and Issue Tracking',
          Description: 'Identify, track, and mitigate project risks and issues in real-time, enhancing project resilience, reducing the likelihood of setbacks, and maintaining project delivery within scope and budget.',
        },
      ],
    },
    ClientHighlight: {
      title: 'Our Benefits',
      image: PMImage,
      description: `ACR: Guiding Your Projects to Success with Precision, Efficiency, and Proven Results`,
      bulletPoints: [
        'Strategic Project Planning: Tailored project strategies aligned with your goals.',
        'Expert Project Management Team: Access top project managers and experts.',
        'Tangible Results: Proven project management solutions that deliver successful outcomes.',
        'Customized Workflows: Tailored project workflows for your specific needs.',
        'Seamless Execution: Projects executed smoothly and efficiently, on time and within budget.',
        'Continuous Improvement: Ongoing project management support for sustained success and optimization.',
      ],
    },
    WorkForYou: {
      bg: 'bg-svc-wfu-pm',
      description: `Our core mission as a project management service provider is to collaborate closely with you, gain a deep understanding of your project needs, and deliver project management solutions that not only align with your expectations but also ensure successful project outcomes.`,
      bulletPoints: [
        {
          title: 'Project Needs Assessment',
          description: `We begin by conducting a thorough analysis of your project requirements, ensuring a comprehensive understanding of your specific project management goals.`,
        },
        {
          title: 'Customized Project Plan',
          description: `Following the assessment, we create a bespoke project plan that outlines the project scope, timeline, and cost, tailored to meet your project management objectives.`,
        },
        {
          title: 'Project Management Partnership',
          description: `Once you approve the plan, we proceed to formalize our project management partnership by signing a detailed agreement, clearly defining the terms, roles, and expectations for a successful project.`,
        },
        {
          title: 'Collaborative Project Execution',
          description: `Our dedicated project management team initiates project execution, maintaining continuous communication and collaboration to keep you informed and engaged throughout the project lifecycle. This ensures your project not only aligns with your vision but also delivers exceptional results.`,
        },
      ],
    },
    ContactUs: {
      contactUsBGImage: 'bg-contact-us-proj-mgm',
      quote: `Whether you're navigating complex project landscapes, embarking on strategic initiatives, or striving to enhance your project management capabilities, we're here to assist. Share your goals, and together, we'll collaborate to bring your project management vision to life.`,
    },
  },
  ApplicationDevelopment: {
    Hero: {
      title: 'Application Development',
      description: `ACR offers a dynamic Application Development portfolio, aimed at empowering your business for the future. With a robust infrastructure, we pave the way for innovation, ensuring our solutions are always at the forefront of technology, uniquely customized to drive your success.`,
      image: 'bg-service-app-dev',
    },
    ServiceCards: {
      title: 'Application Development',
      description: 'Elevate Innovation with our Application Development Solutions!',
      bgColor: 'bg-blue-950',
      cardsBgColor: '',
      Content: [
        {
          Icon: '',
          Name: 'Custom Application Development',
          Description: 'Create tailored applications to meet your unique business needs, boosting operational efficiency and delivering exceptional user experiences by providing precisely the features and functionality your business requires.',
        },
        {
          Icon: '',
          Name: 'Cross-Platform Mobile App Development',
          Description: 'Develop mobile applications that work seamlessly on multiple platforms, maximizing your audience reach, minimizing development costs, and accelerating time-to-market for your mobile apps.',
        },
        {
          Icon: '',
          Name: 'Web Application Development',
          Description: 'Build web applications that provide high performance and user-friendly interfaces, enhancing your online presence, engaging users, and streamlining business operations through responsive, web-based solutions.',
        },
        {
          Icon: '',
          Name: 'DevOps and CI/CD',
          Description: 'Implement DevOps practices and CI/CD pipelines for faster, more reliable application delivery, improving development speed, quality, and collaboration while reducing risks, ensuring your applications stay competitive and reliable.',
        },
        {
          Icon: '',
          Name: 'Application Security Testing',
          Description: 'Conduct comprehensive security testing to protect your applications from vulnerabilities and cyber threats, safeguarding your data and reputation, maintaining compliance, and ensuring your applications are secure and trustworthy.',
        },
        {
          Icon: '',
          Name: 'Legacy Application Modernization',
          Description: 'Update and revitalize legacy applications to align with current technology trends and business requirements, extending the lifespan and functionality of your existing applications, reducing maintenance costs, and staying competitive in a rapidly evolving digital landscape.',
        },
      ],
    },
    ClientHighlight: {
      title: 'Our Benefits',
      image: ADImage,
      description: `ACR: Crafting Innovative Applications for Your Success with Expertise, Precision, and Measurable Impact`,
      bulletPoints: [
        'Strategic App Roadmaps: Tailored app strategies that align with your business objectives.',
        'Expert Development Team: Access top developers and specialists.',
        'Tangible ROI: App solutions that deliver measurable results and a competitive edge.',
        'Customized Solutions: Tailor-made apps for your unique needs and challenges.',
        'Seamless Integration: Integration of apps into your systems with minimal disruption.',
        'Continuous Support: Ongoing app maintenance and support for sustained success and growth.',
      ],
    },
    WorkForYou: {
      bg: 'bg-svc-wfu-app-dev',
      description: `Our primary objective as an application development service provider is to work closely with you, comprehensively grasp your requirements, and deliver top-tier application solutions that not only meet but exceed your expectations, empowering your organization with innovative software.`,
      bulletPoints: [
        {
          title: 'App Vision and Needs Analysis',
          description: `We initiate the process by conducting a detailed analysis of your app vision and specific requirements, ensuring a clear understanding of your application development objectives.`,
        },
        {
          title: 'Bespoke App Development Strategy',
          description: `Following the analysis, we create a customized app development strategy that outlines the project scope, estimated budget, and timeline, tailored to meet your unique app goals.`,
        },
        {
          title: 'App Development Partnership Agreement',
          description: `If the strategy aligns with your vision, we proceed to formalize our app development partnership by signing a comprehensive agreement that clearly defines the terms, roles, and expectations for a successful app development project.`,
        },
        {
          title: 'Collaborative App Design and Development',
          description: ` Our expert app development team kicks off the project, maintaining open lines of communication and collaboration to keep you engaged throughout the app development process. This ensures your app not only aligns with your vision but also delivers an outstanding user experience.`,
        },
      ],
    },
    ContactUs: {
      contactUsBGImage: 'bg-contact-us-app-dev',
      quote: `Whether you're venturing into intricate application development, launching innovative software solutions, or aiming to optimize your digital presence, we're here to assist. Share your objectives, and together, we'll transform your application development vision into a reality.`,
    },
  },
  Staffing: {
    Hero: {
      title: 'Staffing',
      description: `ACR specializes in Staffing solutions that drive your business forward. With a proven track record and a deep talent pool, we connect you with the right people to accelerate your success. Our commitment to excellence ensures your staffing needs are met with the highest standards, empowering your organization to thrive.`,
      image: 'bg-service-staffing',
    },
    ServiceCards: {
      title: 'Staffing',
      description: 'Elevate Your Workforce with our Staffing Solutions!',
      bgColor: 'bg-sky-950',
      cardsBgColor: '',
      Content: [
        {
          Icon: '',
          Name: 'Temporary Staffing Services',
          Description: 'Access skilled temporary personnel to fill short-term staffing gaps, ensuring continuity of business operations, reducing hiring overhead, and meeting fluctuating workforce demands without long-term commitments.',
        },
        {
          Icon: '',
          Name: 'Permanent Recruitment Solutions',
          Description: 'Partner with recruitment experts to find and hire the best-fit permanent employees, enhancing your team with top talent, streamlining the hiring process, and securing long-term growth for your organization.',
        },
        {
          Icon: '',
          Name: 'Contract-to-Hire Staffing',
          Description: `Explore candidate fit through temporary roles before making permanent hiring decisions, minimizing hiring risks, assessing candidates' on-the-job performance, and making informed decisions for permanent placements.`,
        },
        {
          Icon: '',
          Name: 'Executive Search Services',
          Description: `Collaborate with experienced executive search consultants to identify and attract high-level talent, securing exceptional leadership, elevating your organization's performance, and ensuring a bright future with strategic executive hires.`,
        },
        {
          Icon: '',
          Name: 'Project-Based Staffing',
          Description: 'Acquire specialized talent for specific projects or tasks, tailored to your project needs, enhancing project efficiency, meeting tight deadlines, and reducing overhead by leveraging experts for short-term engagements.',
        },
        {
          Icon: '',
          Name: 'Recruitment Process Outsourcing (RPO)',
          Description: 'Outsource your end-to-end recruitment process to experienced professionals, optimizing your recruitment strategy, reducing time-to-fill, and gaining access to a scalable and cost-effective hiring solution.',
        },
      ],
    },
    ClientHighlight: {
      title: 'Our Benefits',
      image: StaffingImage,
      description: `ACR: Staffing Excellence for Your Success with Expertise, Precision, and Seamless Integration`,
      bulletPoints: [
        'Strategic Talent Placement: Tailored staffing solutions that align with your business objectives.',
        'Expert Staffing Team: Access top recruiters and specialists.',
        'Talent That Delivers: Staffing solutions that provide skilled professionals who make an impact.',
        'Customized Staffing: Tailor-made staffing services for your unique workforce needs.',
        'Seamless Integration: Onboard talent seamlessly into your teams and projects.',
        'Continuous Support: Ongoing talent management and optimization for sustained success and growth.',
      ],
    },
    WorkForYou: {
      bg: 'bg-svc-wfu-staffing',
      description: `Our central commitment as a staffing service provider is to engage closely alongside you, thoroughly comprehend your workforce needs, and deliver tailored staffing solutions that not only meet your expectations but also elevate your workforce with the right talent, ensuring your organization's success.`,
      bulletPoints: [
        {
          title: 'Workforce Assessment',
          description: `We start by conducting a comprehensive assessment of your staffing needs, ensuring a deep understanding of your organization's unique workforce requirements.`,
        },
        {
          title: 'Tailored Staffing Solutions',
          description: `Following the assessment, we design custom-tailored staffing solutions that outline the project scope, hiring strategies, and workforce development timeline, all tailored to meet your specific staffing objectives.`,
        },
        {
          title: 'Staffing Partnership Agreement',
          description: `Once you're satisfied with our staffing solutions, we proceed to formalize our staffing partnership by signing a detailed agreement that clearly defines the terms, roles, and expectations for a successful staffing engagement.`,
        },
        {
          title: 'Collaborative Workforce Building',
          description: `Our dedicated staffing team initiates the staffing project, maintaining open lines of communication and collaboration to keep you informed and engaged throughout the workforce development process. This ensures your workforce is not only aligned with your vision but also optimized for your organization's success.`,
        },
      ],
    },
    ContactUs: {
      contactUsBGImage: 'bg-contact-us-staffing',
      quote: `Whether you're navigating the complexities of staffing, building your dream team, or optimizing your workforce, we're here to assist. Share your staffing objectives, and together, we'll work to make your ideal staffing solutions a reality.`,
    },
  },
};

export default ServiceDataConstant;