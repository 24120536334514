import React from "react";
import SolutionDataConstant from "constants/solution";
import SolutionHero from "components/solution/solution-hero";
import ContactUsForm from "pages/contact-us";
import ClientHighlight from "components/common/client-highlight";
import SolutionProcess from "components/solution/solution-process";
import { useEffect } from "react";

function ACRAccelerator() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <SolutionHero 
        title={SolutionDataConstant.ACRAccelerator.Hero.title}
        image={SolutionDataConstant.ACRAccelerator.Hero.image}
        description={SolutionDataConstant.ACRAccelerator.Hero.description}
        highlight={SolutionDataConstant.ACRAccelerator.Hero.highlight}
      />
      <ClientHighlight
        Title={SolutionDataConstant.ACRAccelerator.ClientHighlight.title}
        ImageSrc={SolutionDataConstant.ACRAccelerator.ClientHighlight.image}
        Description={SolutionDataConstant.ACRAccelerator.ClientHighlight.description}
        bulletPoints={SolutionDataConstant.ACRAccelerator.ClientHighlight.bulletPoints}
      />
      <SolutionProcess
        PrimaryImage={SolutionDataConstant.ACRAccelerator.Process.primaryImage}
        SecondaryImage={SolutionDataConstant.ACRAccelerator.Process.secondaryImage}
        Description={SolutionDataConstant.ACRAccelerator.Process.description}
      />
      <ContactUsForm 
        backgroundImage={SolutionDataConstant.ACRAccelerator.ContactUs.contactUsBGImage} 
        quote={SolutionDataConstant.ACRAccelerator.ContactUs.quote}  
      />
    </>
  );
}

export default ACRAccelerator;
