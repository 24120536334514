const SiteMapPaths = {
  'Solutions': [
    {
      text: "ACR PharmLaunch",
      path: "/solution/acr-pharmlaunch"
    },
    {
      text: "ACR Accelerator",
      path: "/solution/acr-accelerator"
    },
    {
      text: "Clinical Operations Study Viability",
      path: "/solution/clinical-operations-viability"
    },
    {
      text: "Clinical Data Management",
      path: "/solution/clinical-data-management"
    },
    {
      text: "Generative AI",
      path: "/solution/generative-chatbot"
    },
],
  'Services': [
    {
      text: "Data Engineering",
      path: "/service/data-engineering",
    },
    {
      text: "Business Intelligence",
      path: "/service/business-intelligence",
    },
    {
      text: "Artificial Intelligence",
      path: "/service/artificial-intelligence",
    },
    {
      text: "Project Management",
      path: "/service/project-management",
    },
    {
      text: "Application Development",
      path: "/service/app-dev",
    },
    {
      text: "Staffing",
      path: "/service/staffing",
    },
  ],
  'Industries': [
    {
      text: "Life Sciences",
      path: "/industry/life-sciences",
    },
    {
      text: "Banking & Financial",
      path: "/industry/banking-financial",
    },
    {
      text: "Retail",
      path: "/industry/retail",
    },
    {
      text: "Automotive Travel & Hospitality",
      path: "/industry/travel-hospitality",
    },
    {
      text: "Hi-Tech",
      path: "/industry/hi-tech",
    },
  ],
};


export default SiteMapPaths