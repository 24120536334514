import LifeSciencesIcon from "assets/icons/industry/life-sciences.svg";
import BankingFinancialIcon from "assets/icons/industry/banking-financial.svg";
import RetailCPGIcon from "assets/icons/industry/retail-cpg.svg";
import TravelHospitalityIcon from "assets/icons/industry/travel-map.svg";
import HiTechIcon from "assets/icons/industry/hi-tech.svg";

const IndustryDataConstant = {
  BankingFinancial: {
    Hero: {
      title: 'Banking & Financial',
      description: `With our extensive expertise in the financial sector, we specialize in enabling banking and financial institutions to establish robust, compliance-driven data infrastructure. This infrastructure empowers your teams with the critical financial data and performance metrics necessary to provide exceptional service to your clients and stakeholders.`

    },
    Cards:[
      {
        title: 'Risk Management and Data Security',
        description: 'Collaborate with us to enhance risk management strategies and fortify data security without compromising sensitive financial information.',
        bulletPoints: [
          'Develop actionable risk assessment metrics', 
          'Implement automated data security protocols', 
          'Identify emerging threats and trends in the financial landscape'
        ],
      },
      {
        title: `Investment Portfolio Optimization`,
        description: `Partner with our team to maximize your investment portfolio's performance while safeguarding client data and privacy.`,
        bulletPoints: [
          'Enhance investment strategies through data-driven insights',
          'Implement automated portfolio monitoring and rebalancing',
          'Identify opportunities and market trends for informed decision-making',
        ],
      },
      {
        title: 'Customer Financial Planning',
        description: 'Join forces with us to provide personalized financial planning services to your customers while adhering to strict data privacy regulations.',
        bulletPoints: [
          'Craft actionable metrics for customer personalization',
          'Develop automated financial planning algorithms',
          'Identify trends in customer financial behavior and needs',
        ],
      },
      {
        title: 'Fraud Detection and Prevention in Banking',
        description: 'Collaborate with us to strengthen your fraud detection while ensuring the security of sensitive financial data.',
        bulletPoints: [
          'Develop actionable fraud detection metrics',
          'Implement automated fraud monitoring systems',
          'Identify evolving fraud patterns and threats in the financial industry',
        ],
      },
    ],
    ClientHighlight:{
      imageSrc: BankingFinancialIcon,
      description: 'ACR Technology joined forces with a forward-thinking banking and financial institution to elevate their data infrastructure. Through close collaboration with key stakeholders spanning operations, customer experience, and finance, we successfully delivered the following capabilities:',
      bulletPoints:[
        'Establishment of a secure, compliance-driven infrastructure in line with industry standards.',
        'Seamless integration with external legacy financial systems.',
        'Empowerment of self-service reporting for in-depth insights into customer experience and operational metrics.',
      ],
    },
  },
  HiTech: {
    Hero: {
      title: 'Hi-Tech',
      description: `With our deep-rooted expertise in the hi-tech sector, we are dedicated to assisting technology companies in crafting state-of-the-art, industry-compliant data infrastructure. This infrastructure empowers your teams with essential hi-tech data and insights, enabling them to drive innovation, solve complex challenges, and stay at the forefront of technological advancements, setting new standards in the industry.`

    },
    Cards:[
      {
        title: 'Product Development and Data Security',
        description: 'Collaborate with us to enhance your product development strategies and fortify data security without compromising critical intellectual property.',
        bulletPoints: [
          'Develop actionable product development metrics', 
          'Implement automated data security protocols', 
          'Identify emerging data security threats and trends in the hi-tech sector',
        ],
      },
      {
        title: 'Market Intelligence and Competitive Analysis',
        description: 'Partner with our team to harness market intelligence and competitive insights in the hi-tech industry while safeguarding data confidentiality.',
        bulletPoints: [
          'Enhance market intelligence through data-driven insights',
          'Implement automated competitive analysis tools',
          'Identify market opportunities and trends for informed decision-making',
        ],
      },
      {
        title: 'Personalized Customer Experiences',
        description: 'Join forces with us to deliver personalized customer experiences and insights while adhering to stringent data privacy regulations.',
        bulletPoints: [
          'Craft actionable metrics for customer personalization',
          'Develop automated customer experience enhancement algorithms',
          'Identify trends in customer behavior and preferences',
        ],
      },
      {
        title: 'Cybersecurity and Data Protection',
        description: 'Collaborate with us to strengthen your cybersecurity and data protection measures in the hi-tech industry, safeguarding sensitive information.',
        bulletPoints: [
          'Develop actionable cybersecurity metrics',
          'Implement automated data protection systems',
          'Identify evolving cyber threats and data security challenges in the hi-tech sector',
        ],
      },
    ],
    ClientHighlight:{
      imageSrc: HiTechIcon,
      description: 'ACR Technology collaborated with a cutting-edge hi-tech firm to elevate their data infrastructure. Through active engagement with key stakeholders spanning product development, technology, and data analytics, we successfully delivered the following capabilities:',
      bulletPoints:[
        'Establishment of a robust and secure data infrastructure that aligns with hi-tech industry standards.',
        'Seamless integration with external technology stacks, enhancing data interoperability and innovation.',
        'Empowerment of self-service reporting, enabling comprehensive insights into product development, technology performance, and analytics metrics.',
      ],
    },
  },
  LifeSciences: {
    Hero: {
      title: 'Life Sciences',
      description: `Drawing upon our extensive background in life sciences, we specialize in facilitating companies within the industry to establish cutting-edge, compliant data infrastructure. This infrastructure equips your teams with critical life sciences data and research insights, empowering them to advance scientific knowledge, deliver innovative solutions, and make a meaningful impact on human health and well-being.`

    },
    Cards:[
      {
        title: 'Clinical Data Management and Privacy',
        description: 'Collaborate with us to enhance your clinical data management strategies and fortify data privacy without compromising sensitive patient information.',
        bulletPoints: [
          'Develop actionable data management metrics', 
          'Implement automated data privacy protocols', 
          'Identify emerging data security threats and trends in the life sciences',
        ],
      },
      {
        title: 'Research Data Optimization',
        description: 'Partner with our team to optimize research data processes and insights in the life sciences while safeguarding data integrity.',
        bulletPoints: [
          'Enhance research strategies through data-driven insights',
          'Implement automated data monitoring and analysis',
          'Identify opportunities and trends in life sciences research for informed decision-making',
        ],
      },
      {
        title: 'Patient-Centric Healthcare Solutions',
        description: 'Join forces with us to deliver patient-centric healthcare solutions, ensuring compliance with stringent data privacy regulations.',
        bulletPoints: [
          'Craft actionable metrics for personalized patient care',
          'Develop automated healthcare algorithms',
          'Identify trends in patient health and medical needs',
        ],
      },
      {
        title: 'Regulatory Compliance and Data Integrity',
        description: 'Collaborate with us to fortify your regulatory compliance and data integrity measures in the life sciences, safeguarding sensitive data.',
        bulletPoints: [
          'Develop actionable compliance metrics',
          'Implement automated data integrity monitoring systems',
          'Identify evolving regulatory requirements and data integrity challenges in the life sciences industry',
        ],
      },
    ],
    ClientHighlight:{
      imageSrc: LifeSciencesIcon,
      description: 'ACR Technology partnered with a dynamic life sciences organization to advance their data infrastructure. By engaging closely with key stakeholders across research, clinical, and regulatory departments, we successfully delivered the following capabilities:',
      bulletPoints:[
        `Establishment of a secure and compliant data infrastructure tailored to the life sciences sector's unique needs.`,
        'Seamless integration with external research and clinical systems, enhancing data interoperability.',
        'Empowerment of self-service reporting, enabling in-depth insights into research, clinical, and regulatory metrics.',
      ],
    },
  },
  RetailCPG: {
    Hero: {
      title: 'Retail',
      description: `Leveraging our wealth of experience, we excel in assisting retail and consumer packaged goods (CPG) companies in constructing data infrastructure that adheres to industry standards and regulations. This infrastructure empowers your teams with essential retail and CPG data, equipping them with the insights and tools needed to enhance customer experiences and drive business growth.`

    },
    Cards:[
      {
        title: 'Market Research and Insights for Retailers',
        description: 'Partner with us to delve deep into consumer behavior and preferences, all while safeguarding their data privacy.',
        bulletPoints: [
          'Develop actionable market insights', 
          'Implement automated, precise customer behavior analytics', 
          'Identify emerging trends within your target customer demographics',
        ],
      },
      {
        title: 'Supply Chain Efficiency and Demand Forecasting',
        description: 'Partner with our team to optimize supply chain operations and demand forecasting while safeguarding data integrity.',
        bulletPoints: [
          'Enhance supply chain efficiency through data-driven insights',
          'Implement automated demand forecasting models',
          'Identify opportunities and trends in supply chain management for informed decision-making',
        ],
      },
      {
        title: 'Personalized Shopping Experiences',
        description: 'Join forces with us to deliver personalized shopping experiences to your customers, respecting data privacy regulations.',
        bulletPoints: [
          'Craft actionable metrics for customer personalization',
          'Develop automated algorithms for personalized shopping recommendations',
          'Identify trends in customer shopping behavior and preferences',
        ],
      },
      {
        title: 'Retail Analytics and Inventory Management',
        description: 'Collaborate with us to strengthen your retail analytics and inventory management processes, ensuring data security and efficiency.',
        bulletPoints: [
          'Develop actionable analytics metrics',
          'Implement automated inventory management systems',
          'Identify evolving trends in retail analytics and inventory control within the CPG industry',
        ],
      },
    ],
    ClientHighlight:{
      imageSrc: RetailCPGIcon,
      description: `ACR Technology partnered with a dynamic retail CPG (Consumer Packaged Goods) company to enhance their data infrastructure. By collaborating closely with key stakeholders across supply chain, marketing, and sales departments, we successfully delivered the following capabilities:`,
      bulletPoints:[
        'Establishment of a resilient and secure data infrastructure tailored to the unique needs of the retail CPG industry.',
        'Seamless integration with external supply chain and sales systems, improving data flow and operational efficiency.',
        'Empowerment of self-service reporting, enabling comprehensive insights into supply chain management, marketing strategies, and sales performance metrics.',
      ],
    },
  },
  TravelHospitality: {
    Hero: {
      title: 'Travel & Hospitality',
      description: `Harnessing our profound knowledge of the travel and hospitality industry, we specialize in helping companies establish robust, guest-centric data infrastructure. This infrastructure equips your teams with vital travel and hospitality insights, enabling them to deliver exceptional experiences, enhance customer satisfaction, and drive success in this dynamic and competitive sector.`

    },
    Cards:[
      {
        title: 'Guest Experience Enhancement',
        description: 'Collaborate with us to enhance guest experiences while safeguarding data privacy in the travel and hospitality sector.',
        bulletPoints: [
          'Develop actionable guest experience metrics', 
          'Implement automated data privacy safeguards', 
          'Identify emerging trends in guest preferences and data security',
        ],
      },
      {
        title: 'Operational Efficiency and Demand Forecasting',
        description: 'Partner with our team to optimize operational efficiency and demand forecasting while safeguarding data integrity.',
        bulletPoints: [
          'Enhance operational efficiency through data-driven insights',
          'Implement automated demand forecasting models',
          'Identify opportunities and trends in operations for informed decision-making',
        ],
      },
      {
        title: 'Personalized Travel Experiences',
        description: 'Join forces with us to deliver personalized travel experiences to your guests, adhering to data privacy regulations.',
        bulletPoints: [
          'Craft actionable metrics for guest personalization',
          'Develop automated algorithms for personalized travel recommendations',
          'Identify trends in guest travel behavior and preferences',
        ],
      },
      {
        title: 'Hospitality Analytics and Reservation Management',
        description: 'Collaborate with us to strengthen your hospitality analytics and reservation management processes, ensuring data security and operational excellence.',
        bulletPoints: [
          'Develop actionable analytics metrics',
          'Implement automated reservation management systems',
          'Identify evolving trends in hospitality analytics and reservation control within the travel and hospitality industry',
        ],
      },
    ],
    ClientHighlight:{
      imageSrc: TravelHospitalityIcon,
      description: 'ACR Technology collaborated with a forward-thinking travel and hospitality organization to elevate their data infrastructure. Through close engagement with key stakeholders across operations, guest experience, and marketing departments, we successfully delivered the following capabilities:',
      bulletPoints:[
        'Establishment of a robust and secure data infrastructure, aligning with the unique demands of the travel and hospitality industry.',
        'Seamless integration with external reservation and guest experience systems, enhancing data flow and operational efficiency.',
        'Empowerment of self-service reporting, enabling comprehensive insights into guest satisfaction, operational performance, and marketing strategies.',
      ],
    },
  },
};

export default IndustryDataConstant;
