import React from "react";
import ThemeContext from "contexts/ThemeContext";
import { ReactComponent as ACRLogo } from "assets/logo/logo.svg";
import { ReactComponent as EmailIcon } from "assets/icons/footer/email.svg";
import { ReactComponent as LinkedInIcon } from "assets/icons/footer/linkedin.svg";
import { ReactComponent as CallIcon } from "assets/icons/footer/call.svg";
import { ThemeConstants } from "constants/theme";
import SiteMapPaths from "constants/site-map";
import { Link } from "react-router-dom";

const SiteMap = () => {
  const { theme } = React.useContext(ThemeContext);
  return (
    <>
      <div className='border-t'>
        <footer id='sitemap'>
          <div className='flex flex-col md:flex-row pt-14'>
            <aside className='flex flex-col justify-center items-center text-center h-full mb-5 md:mb-0 lg:mr-8 xl:mr-14'>
              <ACRLogo className={`w-12 h-12 xl:w-16 xl:h-16`} fill={theme === ThemeConstants.DarkThemeName && "#cfcfcf"} />
              <p className='mt-5 text-xl'>ACR Technology LLC.</p>
            </aside>
            <div className='footer text-base-content sm:justify-center items-center md:items-start md:justify-normal'>
              {Object.keys(SiteMapPaths).map((siteMapPath) => {
                return (
                  <nav className='min-w-[100px] xl:w-auto' key={siteMapPath}>
                    <span className={`footer-title`}>{siteMapPath}</span>
                    {SiteMapPaths[siteMapPath].map(({ path, text }) => (
                      <Link to={path} className='link link-hover md:text-xs' key={path}>
                        {text}
                      </Link>
                    ))}
                  </nav>
                );
              })}
              <nav className='min-w-[100px]'>
                <span className='footer-title'>Company</span>
                <div className='dropdown dropdown-right'>
                  <label tabIndex={0} className='link link-hover'>
                    Contact
                  </label>
                  <ul tabIndex={0} className='dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52'>
                    <li>
                      <a href='mailto:hr@acrtechnology.com' target='_blank' rel='noreferrer'>
                        <EmailIcon fill={theme === ThemeConstants.DarkThemeName && "#cfcfcf"} className='w-4 h-4' /> hr@acrtechnology.com
                      </a>
                    </li>
                    <li>
                      <a href='tel:+17327071429' target='_blank' rel='noreferrer'>
                        <CallIcon fill={theme === ThemeConstants.DarkThemeName && "#cfcfcf"} className='w-4 h-4' /> +1 (732) 707-1429
                      </a>
                    </li>
                  </ul>
                </div>
                <Link to={'/careers'} className='link link-hover'>
                  Careers
                </Link>
              </nav>
              <nav>
                <nav>
                  <span className='footer-title mb-4'>Social</span>
                  <div className='mt-4 grid grid-flow-col gap-4'>
                    <a href='https://www.linkedin.com/company/acr-technology-llc' target='_blank' rel='noreferrer'>
                      <LinkedInIcon fill={theme === ThemeConstants.DarkThemeName && "#cfcfcf"} className='w-10 h-10' />
                    </a>
                    <a href='mailto:hr@acrtechnology.com' target='_blank' rel='noreferrer'>
                      <EmailIcon fill={theme === ThemeConstants.DarkThemeName && "#cfcfcf"} className='w-10 h-10' />
                    </a>
                  </div>
                </nav>
                <span className='footer-title mt-4 -mb-1'>Address</span>
                <a className='link link-hover' href='https://goo.gl/maps/h3hv12F3dVarZkUF8' target='_blank' rel='noreferrer'>
                  2500 Regency Parkway, Cary
                  <br />
                  North Carolina 27518, United States
                </a>
                <a className='link link-hover' href='https://maps.app.goo.gl/irLBVQRJJd6ZY6959' target='_blank' rel='noreferrer'>
                  B/803, Sankalp Iconic Tower, Bopal-Amdi Road
                  <br />
                  Iscon Cross Road, Ahmedabad, Gujarat 380054, India
                </a>
              </nav>
            </div>
          </div>
          <div className='footer py-10 text-base-content items-center justify-center text-center'>
            <aside>
              <p>Copyright © 2023 - All right reserved by ACR Technology LLC.</p>
            </aside>
          </div>
        </footer>
      </div>
    </>
  );
};

export default SiteMap;
