import React from "react";

import { ReactComponent as BrainIcon } from "assets/icons/values/brain.svg";
import { ReactComponent as ThumbsUpIcon } from "assets/icons/values/thumbsup.svg";
import { ReactComponent as ProfessionalIcon } from "assets/icons/values/professional.svg";
import { ReactComponent as AwardIcon } from "assets/icons/values/award.svg";
import { ReactComponent as HappyIcon } from "assets/icons/values/happy.svg";
import { ReactComponent as HandshakeIcon } from "assets/icons/values/handshake.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/values/arrow.svg";
import { ReactComponent as ConversationIcon } from "assets/icons/values/conversation.svg";
import { ReactComponent as CreditCardIcon } from "assets/icons/values/creditcard.svg";
import { ReactComponent as BusinessIcon } from "assets/icons/values/business.svg";
import ThemeContext from "contexts/ThemeContext";
import { ThemeConstants } from "constants/theme";

const Values = () => {
  const { theme } = React.useContext(ThemeContext);
  return (
    <section className="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:padding-bottom-24 lg:px-6">
      <div className="col-span-4 space-y-8 md:grid md:grid-cols-4 md:gap-8 md:space-y-0">
        <div>
          <BrainIcon
            className="w-10 h-10 mb-2 md:w-20 md:h-20"
            fill={theme === ThemeConstants.DarkThemeName && "#cfcfcf"}
          ></BrainIcon>
          <h3 className="mb-2 text-xl font-semibold">
          Act with integrity
          </h3>
        </div>
        <div>
          <ThumbsUpIcon
            className="w-10 h-10 mb-2 md:w-20 md:h-20"
            fill={theme === ThemeConstants.DarkThemeName && "#cfcfcf"}
          ></ThumbsUpIcon>
          <h3 className="mb-2 text-xl font-semibold">Do it right the first time</h3>
        </div>
        <div>
          <ProfessionalIcon
            className="w-10 h-10 mb-2 md:w-20 md:h-20"
            fill={theme === ThemeConstants.DarkThemeName && "#cfcfcf"}
          ></ProfessionalIcon>
          <h3 className="mb-2 text-xl font-semibold">
          Pursue best practices
          </h3>
        </div>
        <div>
          <AwardIcon
            className="w-10 h-10 mb-2 md:w-20 md:h-20"
            fill={theme === ThemeConstants.DarkThemeName && "#cfcfcf"}
          ></AwardIcon>
          <h3 className="mb-2 text-xl font-semibold">
          Earn customer loyalty
          </h3>
        </div>
        <div>
          <HappyIcon
            className="w-10 h-10 mb-2 md:w-20 md:h-20"
            fill={theme === ThemeConstants.DarkThemeName && "#cfcfcf"}
          ></HappyIcon>
          <h3 className="mb-2 text-xl font-semibold">
          Improve service levels
          </h3>
        </div>
        <div>
          <HandshakeIcon
            className="w-10 h-10 mb-2 md:w-20 md:h-20"
            fill={theme === ThemeConstants.DarkThemeName && "#cfcfcf"}
          ></HandshakeIcon>
          <h3 className="mb-2 text-xl font-semibold">
          Be reliable and responsive
          </h3>
        </div>
        <div>
          <ArrowIcon
            className="w-10 h-10 mb-2 md:w-20 md:h-20"
            fill={theme === ThemeConstants.DarkThemeName && "#cfcfcf"}
          ></ArrowIcon>
          <h3 className="mb-2 text-xl font-semibold">
          Increase flexibility for IT
          </h3>
        </div>
        <div>
          <ConversationIcon
            className="w-10 h-10 mb-2 md:w-20 md:h-20"
            fill={theme === ThemeConstants.DarkThemeName && "#cfcfcf"}
          ></ConversationIcon>
          <h3 className="mb-2 text-xl font-semibold">
          Communicate with customers
          </h3>
        </div>
        <div>
          <CreditCardIcon
            className="w-10 h-10 mb-2 md:w-20 md:h-20"
            fill={theme === ThemeConstants.DarkThemeName && "#cfcfcf"}
          ></CreditCardIcon>
          <h3 className="mb-2 text-xl font-semibold">
          Enable customers to better manage costs
          </h3>
        </div>
        <div>
          <BusinessIcon
            className="w-10 h-10 mb-2 md:w-20 md:h-20"
            fill={theme === ThemeConstants.DarkThemeName && "#cfcfcf"}
          ></BusinessIcon>
          <h3 className="mb-2 text-xl font-semibold">
          Adapt to varying business demands
          </h3>
        </div>
      </div>
    </section>
  );
};

export default Values;
