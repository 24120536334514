import React from "react";
import HeroSection from "components/common/hero";
import MissionCards from "components/about/mission-cards";
import Values from "components/about/values";
import { useEffect } from "react";

function OurMission() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <HeroSection 
        primaryText={"Our Mission"}
        secondaryText={"To Accerelate Your Businesses Growth"}
        lightImg={"bg-about-us"}
        darkImg={"bg-about-us"}
      />
      <section className="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
        <div className="text-centerfont-black flex flex-col items-center justify-center">
          <p>At the heart of our success lies a customer-centric philosophy that permeates every facet of our operations. We recognize that our clients are the driving force behind our endeavors, and their success is our paramount goal. With this ethos firmly entrenched, we have assembled a dedicated team of highly skilled professionals who possess not only technical expertise but also a deep understanding of our clients' unique needs and challenges.</p>
          <br/>
          <p>With an unwavering commitment to excellence and a proven track record of delivering unparalleled customer satisfaction, ACR stands as a trailblazer in the realm of IT services. Our expansive portfolio of offerings is designed to empower organizations with transformative solutions that not only meet but exceed their expectations.</p>
          <MissionCards />
          <Values />
        </div>
      </section>
    </>
  );
}

export default OurMission;
