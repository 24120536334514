import { ThemeConstants } from "constants/theme";
import ThemeContext from "contexts/ThemeContext";
import React from "react";

const Accordion = ({ data }) => {
  const [currentActive, setCurrentActive] = React.useState(0);
  const { theme } = React.useContext(ThemeContext);
  return (
    <>
      {data.map(({ title, content }, index) => {
        return (
          <div
            key={index}
            className={`collapse collapse-arrow bg-base-200 rounded-2xl`}
            data-theme={
              currentActive === index
                ? theme === ThemeConstants.DarkThemeName
                  ? ThemeConstants.LightThemeName
                  : ThemeConstants.DarkThemeName
                : theme
            }
            onClick={() => setCurrentActive(index)}
          >
            <input
              type="radio"
              name={`my-accordion`}
              checked={currentActive === index}
            />
            <div className="collapse-title text-2xl font-bold">{title}</div>
            <div className="collapse-content">{content}</div>
          </div>
        );
      })}
    </>
  );
};

export default Accordion;
