import IndustryCard from "components/industry/industry-card";
import IndustryHero from "components/industry/industry-hero";
import IndustryDataConstant from "constants/industry";
import React from "react";
import IndustryClientHighlight from "components/industry/client-highlight";
import WhyUsSection from "components/landing/why-us";
import JoinUsSection from "components/common/join-us";
import { useEffect } from "react";

function HiTech() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <>
    <div className="bg-cover bg-ind-tech">
      <IndustryHero title={IndustryDataConstant.HiTech.Hero.title} description={IndustryDataConstant.HiTech.Hero.description} />
      <IndustryCard cardData={IndustryDataConstant.HiTech.Cards} />
    </div>
    <IndustryClientHighlight
      ImageSrc={IndustryDataConstant.HiTech.ClientHighlight.imageSrc}
      Description={IndustryDataConstant.HiTech.ClientHighlight.description}
      bulletPoints={IndustryDataConstant.HiTech.ClientHighlight.bulletPoints}
    />
    <WhyUsSection />
    <JoinUsSection />
    </>
  );
}

export default HiTech;
