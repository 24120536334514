function IndustryHero({ title, description }) {
  return (
    <>
    <div className="industry-container">
      <div className="industry-content text-white font-poppins">
        <h2 className="industry-title">{title}</h2>
        <p className="industry-description">
          {description}
        </p>
      </div>
    </div>
    </>
  );
}

export default IndustryHero;
