import React from "react";

const JoinUsSection = () => {

  return (
      <section className={`flex flex-col mx-auto h-[300px] max-h-[500px] bg-join-us bg-cover`}>
        <div
          className={`inset-0
          max-w-7xl flex flex-row items-start
          justify-between`}
        >
          <div>
            <div
              className={`font-inter bg-transparent text-base pt-10 pb-7 font-black`}
            >
              Work With Us
            </div>
            <div
              className="text-battleGray text-4xl pb-4 font-inter font-extrabold"
            >
              Embark on an extraordinary consulting expedition to unlock your full potential.
            </div>
          </div>
        </div>
      </section>
  );
};

export default JoinUsSection;
