const BasicCard = ({ title, description, bulletPoints }) => {
  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg bg-gray-200 font-poppins">
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{title}</div>
        <p className="text-gray-500 text-base text-justify">{description}</p>
      </div>
      <ul className="px-6 py-4 pt-1">
        {bulletPoints.map((point, index) => (
          <li key={index} className="text-gray-700 text-base pt-1 list-disc list-outside list-square">
            {point}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BasicCard;