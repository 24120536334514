import React, { useEffect } from "react";
import SolutionDataConstant from "constants/solution";
import SolutionHero from "components/solution/solution-hero";
import ContactUsForm from "pages/contact-us";
import ClientHighlight from "components/common/client-highlight";
import SolutionProcess from "components/solution/solution-process";

function ClinicalDataManagement() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <SolutionHero 
        title={SolutionDataConstant.ClinicalData.Hero.title}
        image={SolutionDataConstant.ClinicalData.Hero.image}
        description={SolutionDataConstant.ClinicalData.Hero.description}
        highlight={SolutionDataConstant.ClinicalData.Hero.highlight}
      />
      <ClientHighlight
        Title={SolutionDataConstant.ClinicalData.ClientHighlight.title}
        ImageSrc={SolutionDataConstant.ClinicalData.ClientHighlight.image}
        Description={SolutionDataConstant.ClinicalData.ClientHighlight.description}
        bulletPoints={SolutionDataConstant.ClinicalData.ClientHighlight.bulletPoints}
      />
      <SolutionProcess
        PrimaryImage={SolutionDataConstant.ClinicalData.Process.primaryImage}
        SecondaryImage={SolutionDataConstant.ClinicalData.Process.secondaryImage}
        Description={SolutionDataConstant.ClinicalData.Process.description}
      />
      <ContactUsForm
        backgroundImage={SolutionDataConstant.ClinicalData.ContactUs.contactUsBGImage}
        quote={SolutionDataConstant.ClinicalData.ContactUs.quote}
      />
    </>
  );
}

export default ClinicalDataManagement;
