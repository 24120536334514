import PharmLaunchIcon from "assets/icons/solution/pharmlaunch.svg";
import AccerelatorIcon from "assets/icons/solution/accerelator.svg";
import ClinicalOpsIcon from "assets/icons/solution/clinical-ops.svg";
import ClinicalDataIcon from "assets/icons/solution/clinical-data.svg";
import ChatbotIcon from "assets/icons/solution/chatbot.svg";
import PharmLaunchPrimaryImage from "assets/backgrounds/solution/process/pharmlaunch-primary.jpg";
import PharmLaunchSecondaryImage from "assets/backgrounds/solution/process/pharmlaunch-secondary.png";
import AccerelatorPrimaryImage from "assets/backgrounds/solution/process/accerelator-primary.png";
import AccerelatorSecondaryImage from "assets/backgrounds/solution/process/accerelator-secondary.webp";
import ClinicalDataPrimaryImage from "assets/backgrounds/solution/process/clinicaldata-primary.png";
import ClinicalDataSecondaryImage from "assets/backgrounds/solution/process/clinicaldata-secondary.jpg";
import ChatbotPrimaryImage from "assets/backgrounds/solution/process/chatbot-primary.png";
import ChatbotSecndaryImage from "assets/backgrounds/solution/process/chatbot-secondary.png";
import ClinicalOpsPrimaryImage from "assets/backgrounds/solution/process/clinicalops-primary.png";
import ClinicalOpsSecondaryImage from "assets/backgrounds/solution/process/clinicalops-secondary.png";

const SolutionDataConstant = {
  ACRPharmLaunch: {
    Hero: {
      title: 'ACR PharmLaunch',
      highlight: `Maximize the success of your pharmaceutical product launch with our expert guidance and support.`,
      description: `Our seasoned expertise and precise guidance empower you to strategically plan pharmaceutical launches, ensuring compliance with industry regulations and unlocking the full potential for your product's success in the market.`,
      image: 'bg-pharm-launch'
    },
    ContactUs: {
      contactUsBGImage: 'bg-contact-us-pharmlaunch',
      quote: `Whether you're embarking on a pharmaceutical product launch, venturing into uncharted markets, or aiming to excel in the industry, we're here to support your journey. Share your vision, and let's collaborate to turn it into a successful reality.`,
    },
    ClientHighlight: {
      title: 'Why ACR?',
      image: PharmLaunchIcon,
      description: `ACR's Pharm Launch is your gateway to unparalleled success in the pharmaceutical industry. With a focus on innovation, sustainability, and seamless integration, we offer tailored solutions that boost your efficiency and position you as a leader in your field. Our commitment to carbon neutrality, adherence to global standards, and strategic partnerships ensure that partnering with us will set you on a path to a greener, more prosperous future. Choose ACR's Pharm Launch to revolutionize your pharmaceutical business and gain a competitive advantage in this rapidly evolving industry. Your success is our priority, and we're ready to help you thrive in the world of pharmaceuticals.`,
      bulletPoints: [
        'Cutting-Edge Innovation: Stay ahead with groundbreaking pharmaceutical solutions.',
        'Streamlined Efficiency: Save time and resources for core operations.',
        'Green & Sustainable: Reduce your carbon footprint and promote sustainability.',
        'Trusted Partnerships: Benefit from top-quality industry alliances.',
        'Global Recognition: Align with international standards for credibility.',
        'Tailored Success: Solutions crafted to meet your unique needs and goals.',
      ],
    },
    Process: {
      primaryImage: PharmLaunchPrimaryImage,
      secondaryImage: PharmLaunchSecondaryImage,
      description: `ACR's Pharm Launch process and methodology are engineered for pharmaceutical success. Our innovative solutions, tailored approach, and dedication to sustainability streamline your operations and reduce your carbon footprint. With a proven track record, trust ACR to launch your pharmaceutical business toward unparalleled success.`,
    },
  },
  ClinicalData: {
    Hero: {
      title: 'Clinical Data Management',
      highlight: `Navigate clinical data management with expert guidance and support for superior results.`,
      description: 'Unlock the full potential of your clinical data with our expert guidance and comprehensive support, ensuring you navigate the complexities of clinical data management with precision and confidence.',
      image: 'bg-clinical-data-mgm'
    },
    ContactUs: {
      contactUsBGImage: 'bg-contact-us-data-mgm',
      quote: `Whether you're navigating intricate clinical data, launching a new research project, or seeking to optimize your data management processes, we're here to assist. Share your objectives, and together, we'll bring your vision to fruition.`,
    },
    ClientHighlight: {
      title: 'Why ACR?',
      image: ClinicalDataIcon,
      description: `ACR's Clinical Data Management is your key to excellence in the world of clinical research. Our cutting-edge solutions, tailored to your specific needs, ensure that your clinical data is efficiently managed and of the highest quality. We seamlessly integrate with your existing processes, saving you time and resources, so you can focus on your research goals. Our dedication to data integrity and compliance with industry standards guarantees trust and credibility. With ACR's Clinical Data Management, you're not just managing data; you're ensuring the success and reliability of your clinical trials. Choose us to gain a competitive edge and drive innovation in the field of clinical research. Your success is our mission, and we're here to help you achieve it.`,
      bulletPoints: [
        'Data Quality Assurance: Ensure the accuracy and reliability of your clinical data.',
        'Efficiency and Streamlining: Improve data processes for time and resource savings.',
        'Seamless Integration: Integrate with existing clinical systems effortlessly.',
        'Compliance and Security: Maintain strict adherence to regulatory standards.',
        'Actionable Insights: Extract meaningful insights from your clinical data.',
        'Cost-Effective Solutions: Optimize operations while reducing costs.',
      ],
    },
    Process: {
      primaryImage: ClinicalDataPrimaryImage,
      secondaryImage: ClinicalDataSecondaryImage,
      description: `ACR's process and methodology for clinical data management deliver precision, security, and compliance. With a proven track record, our approach ensures your clinical data is of the highest quality, providing you with reliable insights and peace of mind. Choose ACR for the gold standard in data management.`,
    },
  },
  ACRAccelerator: {
    Hero: {
      title: 'ACR Accelerator',
      highlight: `Maximize your data's potential with seamless automation and swift source integration.`,
      description: 'Realize the untapped potential of your data through the ACR Accelerator, a cutting-edge solution for streamlining data pipelines and swiftly incorporating a wide range of data sources.',
      image: 'bg-accerelator'
    },
    ContactUs: {
      contactUsBGImage: 'bg-contact-us-accerelator',
      quote: `Whether you're streamlining pharmaceutical product launch data, integrating diverse sources, or seeking an efficient data automation solution, we're here to facilitate your objectives. Share your vision, and together, we'll make it a seamless reality.`,
    },
    ClientHighlight: {
      title: 'Why ACR?',
      image: AccerelatorIcon,
      description: `ACR's Accerator solutions are your gateway to enhanced efficiency, accuracy, and insights. We specialize in streamlining complex processes, seamlessly connecting disparate systems, and automating tedious tasks, giving you more time to focus on strategic decision-making. Our custom-tailored solutions effortlessly integrate into your existing workflows, saving valuable resources and ensuring a smooth transition to an accelerated future. With a relentless commitment to excellence and compliance with industry standards, we guarantee trust and reliability in your operations. Choose ACR's Accerator to gain a competitive edge and revolutionize the way you manage and utilize your resources. Your success is our priority, and we're here to help you achieve it through the power of Accerator.`,
      bulletPoints: [
        'Efficiency Enhancement: Streamline processes, boost productivity, and save time.',
        'Seamless Integration: Integrate diverse systems effortlessly for a unified workflow.',
        'Automated Tasks: Reduce manual labor and eliminate repetitive, time-consuming tasks.',
        'Data-Driven Insights: Harness data for smarter decision-making and enhanced analytics.',
        'Cost Savings: Decrease operational costs and increase profitability.',
        'Competitive Edge: Stay ahead with state-of-the-art automation and integration.',
      ],
    },
    Process: {
      primaryImage: AccerelatorPrimaryImage,
      secondaryImage: AccerelatorSecondaryImage,
      description: `ACR's Accerator embodies a process and methodology designed for operational excellence. Our approach seamlessly integrates systems, automates tasks, and leverages data for strategic decision-making, resulting in enhanced efficiency and cost savings. With a proven track record, trust ACR to accelerate your operations and drive innovation in your business.`,
    },
  },
  ClinicalOps: {
    Hero: {
      title: 'Clinical Operations Study Viability',
      highlight: `Elevate your clinical operations studies to success with our expert guidance, ensuring viability and efficiency.`,
      description: 'Reliable, results-driven guidance for your clinical operations study viability; we bring extensive expertise and a track record of success to ensure compliance and maximize your research efficiency.',
      image: 'bg-clinical-ops'
    },
    ContactUs: {
      contactUsBGImage: 'bg-contact-us-clinical-study',
      quote: `Whether you're facing complex clinical study challenges, embarking on a new research initiative, or striving to optimize study viability, we're here to support your goals. Share your vision, and together, we'll make it a reality.`,
    },
    ClientHighlight: {
      title: 'Why ACR?',
      image: ClinicalOpsIcon,
      description: `ACR's Clinical Operations Study Viability services are your key to a successful and efficient clinical research journey. We offer comprehensive solutions tailored to your specific study needs, ensuring that your clinical trials are not only feasible but also cost-effective and streamlined. Our expertise seamlessly integrates with your operational processes, saving you time and resources, and allowing you to focus on the vital aspects of your research. Our dedication to data integrity, compliance with industry standards, and a track record of success guarantee trust and credibility in your clinical operations. Choose ACR's Study Viability services to gain a competitive edge and to ensure the success of your clinical trials. Your success is our mission, and we're here to help you thrive in the realm of clinical research.`,
      bulletPoints: [
        'Optimized Clinical Trials: Ensure the success of your trials through tailored solutions.',
        'Cost-Effective Strategies: Maximize efficiency while minimizing expenses.',
        'Seamless Integration: Smoothly integrate with your existing clinical operations.',
        'Regulatory Compliance: Rest assured with adherence to industry standards.',
        'Data Integrity: Trustworthy data management for accurate insights.',
        'Strategic Decision-Making: Make informed decisions for successful clinical research.',
      ],
    },
    Process: {
      primaryImage: ClinicalOpsPrimaryImage,
      secondaryImage: ClinicalOpsSecondaryImage,
      description: `ACR's Clinical Operations Study Viability methodology is your blueprint for clinical research success. Our strategic approach ensures optimized trials, cost-effective strategies, seamless integration, and robust data management, all while maintaining strict regulatory compliance. With a proven track record, trust ACR to guide your clinical operations toward a successful and efficient research journey.`,
    },
  },
  Chatbot: {
    Hero: {
      title: 'Generative AI',
      highlight: `Transform interactions with advanced generative AI – expertly guided and effortless.`,
      description: 'Enhance your customer engagement with cutting-edge Generative AI technology. We offer a wealth of experience and dependable technical support to elevate your AI-driven interactions.',
      image: 'bg-chatbot'
    },
    ContactUs: {
      contactUsBGImage: 'bg-contact-us-chatbot',
      quote: `Whether you aim to address a challenging problem, initiate a new project, or enhance your business with AI-driven chatbots, we're here to help. Share your vision, and we'll collaborate to bring it to life.`,
    },
    ClientHighlight: {
      title: 'Why ACR?',
      image: ChatbotIcon,
      description: `ACR's Chatbot Solutions are your gateway to superior customer service and business efficiency. Our state-of-the-art chatbot technology offers personalized, round-the-clock support, ensuring your customers' needs are met instantly. Seamlessly integrating into your digital ecosystem, our chatbots save time, resources, and improve user satisfaction. With our dedication to AI advancements and industry standards, we guarantee a reliable and innovative solution. Choose ACR's Chatbot Solutions to give your business a competitive edge and revolutionize the way you engage with your audience. Your success is our top priority, and we're here to help you achieve it through the power of chatbots.`,
      bulletPoints: [
        'Instant Customer Support: Provide 24/7 personalized assistance to your customers.',
        'Efficiency and Cost Savings: Streamline operations and reduce support costs.',
        'Seamless Integration: Easily integrate chatbots into your existing systems.',
        'AI-Powered Insights: Gain valuable insights from data for smarter decisions.',
        'User Satisfaction: Enhance customer experience with quick and accurate responses.',
        'Competitive Advantage: Lead with innovative, AI-driven customer engagement.',
      ],
    },
    Process: {
      primaryImage: ChatbotPrimaryImage,
      secondaryImage: ChatbotSecndaryImage,
      description: `ACR's Chatbot methodology is your gateway to customer service excellence. Our process seamlessly integrates advanced AI chatbots into your customer engagement strategy, delivering instant, efficient, and personalized support. With a proven track record, trust ACR to enhance user satisfaction and provide a competitive edge through innovative chatbot solutions.`,
    },
  },
};

export default SolutionDataConstant;
