import IndustryCard from "components/industry/industry-card";
import IndustryHero from "components/industry/industry-hero";
import IndustryDataConstant from "constants/industry";
import React from "react";
import IndustryClientHighlight from "components/industry/client-highlight";
import WhyUsSection from "components/landing/why-us";
import JoinUsSection from "components/common/join-us";
import { useEffect } from "react";

function LifeSciences() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className="bg-cover bg-ind-lfs">
      <IndustryHero title={IndustryDataConstant.LifeSciences.Hero.title} description={IndustryDataConstant.LifeSciences.Hero.description} />
      <IndustryCard cardData={IndustryDataConstant.LifeSciences.Cards} />
    </div>
    <IndustryClientHighlight
      ImageSrc={IndustryDataConstant.LifeSciences.ClientHighlight.imageSrc}
      Description={IndustryDataConstant.LifeSciences.ClientHighlight.description}
      bulletPoints={IndustryDataConstant.LifeSciences.ClientHighlight.bulletPoints}
    />
    <WhyUsSection />
    <JoinUsSection />
    </>
  );
}

export default LifeSciences;
