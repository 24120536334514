import Card from "components/common/card";
import React from "react";
import adIcon from "assets/icons/landing/svc-ad.svg";
import pmIcon from "assets/icons/landing/svc-pm.svg";
import dmIcon from "assets/icons/landing/svc-dm.svg";
import biIcon from "assets/icons/landing/svc-bi.svg";
import aiIcon from "assets/icons/landing/svc-ai.svg";
import stfIcon from "assets/icons/landing/svc-staffing.svg";

const ServicesSection = () => {
  return (
    <section
      className={`flex flex-col mx-auto overflow-hidden py-20 bg-cover bg-landing-service`}
    >
      <div className="text-center font-beckman font-black text-3xl md:text-5xl flex flex-col items-center justify-center">
        <div className="w-fit flex flex-col justify-center items-center text-white">
          Our Services
          <div className="flex flex-row justify-center items-center w-full mt-2">
            <div className="flex flex-row justify-center items-center w-full mt-2">
              <div
                className={`border-b-2 border-solid border-white w-full`}
              ></div>
              <div
                className={`border-b-4 border-solid border-white w-full`}
              ></div>
              <div
                className={`border-b-2 border-solid border-white w-full`}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2  mt-5">
            <Card
              cardImage={pmIcon} 
              cardMessage="Optimize your project management with our tailored solutions. We offer a suite of tools and expertise, spanning from project planning and scheduling to team collaboration and real-time tracking. With our services, you can streamline your workflows, enhance communication, and ensure efficient project delivery, ultimately driving your organization's success."
              cardTitle="Project Management"
              containerClass="h-full"
              cardRedirect="/service/project-management"
            />
            <Card
              cardImage={dmIcon} 
              cardMessage="Unleash the full potential of your data with our comprehensive suite of expert data engineering services, encompassing Data Mesh, Data Fabric, and Data Integration. We provide the essential infrastructure and expertise needed to break down data silos, create a unified data fabric, and seamlessly integrate diverse data sources, enabling you to make data-driven decisions, gain a competitive edge, and drive innovation within your organization."
              cardTitle="Data Engineering"
              containerClass="h-full"
              cardRedirect="/service/data-engineering"
              />
            <Card
              cardImage={stfIcon} 
              cardMessage="Elevate your staffing solutions with our comprehensive services. We specialize in talent acquisition, workforce management, and human resources consulting to ensure that your organization has the right people in the right positions. With our expertise, you can optimize your workforce, drive productivity, and achieve your staffing goals efficiently and effectively."
              cardTitle="Staffing"
              containerClass="h-full"
              cardRedirect="/service/staffing"
            />
            <Card
              cardImage={biIcon} 
              cardMessage="Elevate your business intelligence with our cutting-edge solutions. Our suite of services includes advanced data analytics, real-time reporting, and interactive dashboards, empowering you to extract valuable insights and make informed decisions swiftly and effectively."
              cardTitle="Business Intelligence"
              containerClass="h-full"
              cardRedirect="/service/business-intelligence"
              />
            <Card
              cardImage={aiIcon} 
              cardMessage="Harness the transformative potential of Artificial Intelligence with our innovative solutions. From machine learning algorithms to natural language processing, our AI expertise empowers you to automate processes, gain predictive insights, and drive intelligent decision-making within your organization, ushering in a new era of efficiency and innovation."
              cardTitle="Artificial Intelligence"
              containerClass="h-full"
              cardRedirect="/service/artificial-intelligence"
              />
            <Card
              cardImage={adIcon} 
              cardMessage="Elevate your application development with our comprehensive services. From concept to deployment, our team specializes in crafting robust, scalable, and user-centric applications. We bring your ideas to life, ensuring that your software solutions are not just functional, but also seamlessly integrate into your business operations."
              cardTitle="Application Development"
              containerClass="h-full"
              cardRedirect="/service/app-dev"
            />
      </div>
    </section>
  );
};

export default ServicesSection;
