import React from "react";
import lsImage from '../../../assets/backgrounds/landing/ls.jpg'
import aiImage from '../../../assets/backgrounds/landing/ai.webp'
import staffingImage from '../../../assets/backgrounds/landing/staffing.webp'
import deImage from '../../../assets/backgrounds/landing/de.webp'
import pmImage from '../../../assets/backgrounds/landing/pm.jfif'
import { useMediaQuery } from 'react-responsive';

const LandingHero = () => {

  const isSmallScreen = useMediaQuery({ maxWidth: 768 });


  return (
      <section className="overflow-x-hidden overflow-y-hidden bg-cover bg-center bg-landing-hero relative" style={{ overflowX: "hidden", overflowY: "hidden" }} quarkly-title="Hero-6">
        <div style={{ width: "100%", flex: "1 1 0%", maxWidth: "100%" }}>
          <div style={{ minWidth: "100px", minHeight: "100px", display: "flex", flexDirection: "row" }}>
            <div style={{ minWidth: "100px", minHeight: "100px", width: isSmallScreen ? '100%' : '55%', padding: isSmallScreen ? "100px 40px 60px 40px": "140px 80px 140px 80px" }} >
                <h1 className="mb-5 font-sans text-white font-extrabold text-2xl leading-6 md:text-5xl md:leading-tight">
                  ACR Technology is a consulting and a staffing company
                </h1>
              <div className="max-w-3xl mx-auto">
                <p className="text-justify" style={{ margin: "0px 0px 50px 0px", fontFamily: "sans-serif", color: '#fff', fontWeight: 50, fontSize: "20px"}}>
                  Our consulting and staffing company provides a comprehensive suite of solutions, encompassing strategic consulting, talent acquisition, workforce management, and data-driven insights. With ACR Technology, organizations can optimize their operations, enhance workforce performance, and achieve greater productivity.
                </p>
              </div>
            </div>
            <div className='hidden md:flex' style={{ minWidth: "100px", minHeight: "100px", width: "758px", margin: "0px -50px 0px 0px", position: "static", bottom: "-298px", height: "716px", left: "614px", right: "-52px", top: "-28px", flex: "1 1 0%", alignSelf: "center", padding: "0px 0px 80px 0px" }}>
              <div style={{ minWidth: "100px", minHeight: "100px", display: "flex", alignItems: "center", width: "33%", margin: "50px 0 0 0" }}>
                <img
                  src={lsImage}
                  alt="hero 1"
                  style={{ display: "block", height: "400px", borderRadius: "25px", objectFit: "cover", width: "250px" }}
                />
              </div>
              <div style={{ minWidth: "100px", minHeight: "100px", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", width: "33%", alignSelf: "center", margin: "50px 0 0 15px" }}>
                <img
                  src={aiImage}
                  alt="hero 2"
                  style={{ display: "block", maxWidth: "100%", height: "350px", objectFit: "cover", borderRadius: "15px", marginBottom: "15px", width: "250px" }}
                />
                <img
                  src={staffingImage}
                  alt="hero 3"
                  style={{ display: "block", maxWidth: "100%", height: "300px", objectFit: "cover", borderRadius: "15px", marginBottom: "15px", width: "200px" }}
                />
              </div>
              <div style={{ minWidth: "100px", minHeight: "100px", display: "flex", alignItems: "center", flexDirection: "column", width: "33%", justifyContent: "flex-start", margin: "0 0 0 15px" }}>
                <img
                  src={deImage}
                  alt="hero 4"
                  style={{ display: "block", maxWidth: "100%", height: "400px", objectFit: "cover", borderRadius: "15px", marginBottom: "15px", width: "200px" }}
                />
                <img
                  src={pmImage}
                  alt="hero 5"
                  style={{ display: "block", maxWidth: "100%", height: "450px", objectFit: "cover", borderRadius: "15px", marginBottom: "15px", width: "200px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default LandingHero;
