import React from "react";
import SuccessSection from "components/landing/success";
import ServicesSection from "components/landing/services";
import SolutionsSection from "components/landing/solutions";
import StatsSection from "components/landing/stats";
import WhyUsSection from "components/landing/why-us";
import LandingHero from "components/landing/hero";
import { useEffect } from "react";

function LandingPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <LandingHero />
      <ServicesSection />
      <SolutionsSection />
      <SuccessSection />
      <StatsSection />
      <WhyUsSection />
    </>
  );
}

export default LandingPage;
