import ServiceHero from "components/service/service-hero";
import ServiceDataConstant from "constants/services";
import React,{ useEffect }  from "react";
import ContactUsForm from "pages/contact-us";
import ServiceCards from "components/service/service-cards";
import WhyUsSection from "components/landing/why-us";
import WorkForYouSection from "components/common/work-for-you";
import ClientHighlight from "components/common/client-highlight";

function DataEngineering() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <ServiceHero
        image={ServiceDataConstant.DataEngineering.Hero.image}
        title={ServiceDataConstant.DataEngineering.Hero.title}
        description={ServiceDataConstant.DataEngineering.Hero.description} 
      />
      <ServiceCards 
        title={ServiceDataConstant.DataEngineering.ServiceCards.title}
        description={ServiceDataConstant.DataEngineering.ServiceCards.description}
        bgColor={ServiceDataConstant.DataEngineering.ServiceCards.bgColor}
        cardsBgColor={ServiceDataConstant.DataEngineering.ServiceCards.cardsBgColor}
        cardContent={ServiceDataConstant.DataEngineering.ServiceCards.Content}
      />
      <ClientHighlight
        Title={ServiceDataConstant.DataEngineering.ClientHighlight.title}
        ImageSrc={ServiceDataConstant.DataEngineering.ClientHighlight.image}
        Description={ServiceDataConstant.DataEngineering.ClientHighlight.description}
        bulletPoints={ServiceDataConstant.DataEngineering.ClientHighlight.bulletPoints}
      />
      <WorkForYouSection 
        bg={ServiceDataConstant.DataEngineering.WorkForYou.bg}
        description={ServiceDataConstant.DataEngineering.WorkForYou.description}
        bulletPoints={ServiceDataConstant.DataEngineering.WorkForYou.bulletPoints}
      />
      <WhyUsSection />
      <ContactUsForm
        backgroundImage={ServiceDataConstant.DataEngineering.ContactUs.contactUsBGImage}
        quote={ServiceDataConstant.DataEngineering.ContactUs.quote}
      />
    </>
  );
}

export default DataEngineering;
