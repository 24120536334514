import IndustryCard from "components/industry/industry-card";
import IndustryHero from "components/industry/industry-hero";
import IndustryDataConstant from "constants/industry";
import React, { useEffect } from "react";
import IndustryClientHighlight from "components/industry/client-highlight";
import WhyUsSection from "components/landing/why-us";
import JoinUsSection from "components/common/join-us";

function RetailCPG() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className="bg-cover bg-ind-retail">
      <IndustryHero title={IndustryDataConstant.RetailCPG.Hero.title} description={IndustryDataConstant.RetailCPG.Hero.description} />
      <IndustryCard cardData={IndustryDataConstant.RetailCPG.Cards} />
    </div>
    <IndustryClientHighlight
      ImageSrc={IndustryDataConstant.RetailCPG.ClientHighlight.imageSrc}
      Description={IndustryDataConstant.RetailCPG.ClientHighlight.description}
      bulletPoints={IndustryDataConstant.RetailCPG.ClientHighlight.bulletPoints}
    />
    <WhyUsSection />
    <JoinUsSection />
    </>
  );
}

export default RetailCPG;
