import React, { useEffect } from "react";
import SolutionDataConstant from "constants/solution";
import SolutionHero from "components/solution/solution-hero";
import ContactUsForm from "pages/contact-us";
import ClientHighlight from "components/common/client-highlight";
import SolutionProcess from "components/solution/solution-process";

function ClinicalOperationsViability() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <SolutionHero 
        title={SolutionDataConstant.ClinicalOps.Hero.title}
        image={SolutionDataConstant.ClinicalOps.Hero.image}
        description={SolutionDataConstant.ClinicalOps.Hero.description}
        highlight={SolutionDataConstant.ClinicalOps.Hero.highlight}
      />
      <ClientHighlight
        Title={SolutionDataConstant.ClinicalOps.ClientHighlight.title}
        ImageSrc={SolutionDataConstant.ClinicalOps.ClientHighlight.image}
        Description={SolutionDataConstant.ClinicalOps.ClientHighlight.description}
        bulletPoints={SolutionDataConstant.ClinicalOps.ClientHighlight.bulletPoints}
      />
      <SolutionProcess
        PrimaryImage={SolutionDataConstant.ClinicalOps.Process.primaryImage}
        SecondaryImage={SolutionDataConstant.ClinicalOps.Process.secondaryImage}
        Description={SolutionDataConstant.ClinicalOps.Process.description}
      />
      <ContactUsForm
        backgroundImage={SolutionDataConstant.ClinicalOps.ContactUs.contactUsBGImage}
        quote={SolutionDataConstant.ClinicalOps.ContactUs.quote}  
      />
    </>
  );
}

export default ClinicalOperationsViability;
