import React from "react";

const SolutionProcess = ({ PrimaryImage, SecondaryImage, Description }) => {
  return (
    <section className={`flex flex-col mx-auto overflow-hidden py-10`}>
      <div className='text-center font-beckman font-black text-3xl md:text-5xl pb-10 flex flex-col items-center justify-center'>
        <div className={`w-fit flex flex-col justify-center items-center}`}>
          Process & Methodology
        </div>
      </div>
      <div className="solution-process-image flex flex-shrink items-center justify-center">
        <img src={PrimaryImage} alt="solution primary" />
      </div>
      <div className='w-fit flex flex-col justify-center items-center text-justify'>
        {Description}
      </div>
      <div className="solution-process-image flex items-center justify-center">
        <img src={SecondaryImage} alt="solution secondary" />
      </div>
    </section>
  );
};

export default SolutionProcess;
