import React from "react";
import { NavLink } from "react-router-dom";

const Card = ({cardImage='', cardTitle = '', cardMessage = '', onClose = undefined, containerClass = '', cardRedirect = '' }) => {
  return (
      <div className={`card text-neutral-content w-fit mx-auto ${containerClass}`}>
        <div className="card-body">
          {onClose && <div className="card-actions justify-end">
            <button className="btn btn-square btn-sm" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                stroke="currentColor"
                >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                  />
              </svg>
            </button>
          </div>}
          <figure className="w-20 h-20"><img src={cardImage} alt="" className="w-20 h-20"/></figure>
          <h2 className="card-title text-[#a6adba]">{cardTitle}</h2>
          <p className="text-justify">{cardMessage}</p>
          <NavLink className="underline" to={cardRedirect}>Learn more {'>'}</NavLink>
        </div>
      </div>
  );
};

export default Card;
