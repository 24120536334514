import React from "react";
import ThemeContext from "contexts/ThemeContext";
import { ThemeConstants } from "constants/theme";

function IndustryClientHighlight({ ImageSrc, Description, bulletPoints }) {
  const { theme } = React.useContext(ThemeContext);

  return (
    <>
    <div className="industry-image-description-container">
      <div className="hidden industry-image md:flex items-center justify-center">
        <img src={ImageSrc} alt="industry highlight" />
      </div>
      <div className="industry-image-description">
        <div className="industry-image-description-scrollable">
        <div className='text-center font-beckman font-black text-5xl flex flex-col items-center justify-center'>
        <div className={`w-fit flex flex-col justify-center}`}>
          Client Highlight
          <div className='flex flex-row w-full mt-2'>
            <div className={`border-b-2 border-solid ${theme === ThemeConstants.DarkThemeName ? "border-[#a6adba]" : "border-[#1f2937]"} w-full`}></div>
            <div className={`border-b-4 border-solid ${theme === ThemeConstants.DarkThemeName ? "border-[#a6adba]" : "border-[#1f2937]"} w-full`}></div>
            <div className={`border-b-2 border-solid ${theme === ThemeConstants.DarkThemeName ? "border-[#a6adba]" : "border-[#1f2937]"} w-full`}></div>
          </div>
        </div>
      </div>
      <div className="justify-center flex items-center md:hidden">
        <img src={ImageSrc} alt="industry highlight" />
      </div>
      <div className={`flex flex-col justify-center items-center pt-10`}>
        <p className="text-justify">
          {Description}
        </p>
      </div>
      </div>
      <div className={`flex flex-col pt-1`}>
        <ul className="px-6 py-4 pt-4">
          {
            bulletPoints.map((point, index) => (
              <li key={index} className="text-gray-500 text-base list-disc pt-1 list-outside list-square">
                {point}
              </li>
            ))
          }
        </ul>
      </div>
      </div>
    </div>
    </>
  );
}

export default IndustryClientHighlight;
