import ThemeContext from "contexts/ThemeContext";
import React, { useLayoutEffect, useEffect } from "react";
import { Link } from "react-router-dom";

function NotFound() {
  const { theme } = React.useContext(ThemeContext);

  const [navbarHeight, setNavbarHeight] = React.useState(0);


  useLayoutEffect(() => {
    const navbar = document.getElementById('navbar');
    setNavbarHeight(navbar.clientHeight);
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='flex items-center justify-center' style={{height: `max(500px, calc(100vh - ${navbarHeight}px))`}}>
      <div className='text-center'>
        <h1 className='m-3 cursor-default text-9xl border-dashed border-b-2'>
          <span className='fadeIn delay-200'>4</span>
          <span className='fadeIn delay-300'>0</span>
          <span className='fadeIn delay-400'>4</span>
        </h1>
        <h3 className='m-3 cursor-default fadeIn text-xl'>PAGE NOT FOUND</h3>
        <Link to='/'>
          <button className='btn my-[50px]' data-theme={theme}>Return To Home</button>
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
